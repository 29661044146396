import { ADD_STATION, SET_STATIONS } from "../../types/types";

type InitialState = {
  stations: {
    stations: any[];
    limit: number;
    currentPage: number;
    total: number;
    totalPages: number
  };
};

const initialState: InitialState = {
  stations: {
    limit: 30,
    currentPage: 1,
    stations: [],
    total: 0,
    totalPages: 1
  },
};
type Action = {
  type: "ADD_STATION" | "SET_STATIONS";
  station?: any;
  stations?: any[];
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ADD_STATION:
      return {
        ...state,
        stations: {
          ...state.stations,
          stations: [
            {
              ...action.station,
            },
            ...state.stations.stations,
          ],
        },
      };

      
    case SET_STATIONS:
      return {...state, stations: action.stations};

    default:
      return state;
  }
};
