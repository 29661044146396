import * as React from "react";
import "./Transactions.scss";
import MainLayout from "../../shared/components/MainLayout/MainLayout";
import ContentLayout from "../../shared/components/ContentLayout/ContentLayout";
import CardContainer from "../../shared/components/CardContainer/CardContainer";
import Card from "../../shared/components/Card/Card";
import Table from "../../shared/components/Table/Table";
import TransactionProvider from "./TransactionProvider/TransactionProvider";
import Modal from "../../shared/components/Modal/Modal";
import { DOWNLOAD } from '../../core/config/Api';
import { objInterface } from '../../shared/utils/interfaces';
import Filter from '../../shared/components/Filter/Filter';
import Spinner from "../../shared/components/Spinner/Spinner";


const Transactions: React.FC = () => {
  const downloadSales = async (user: objInterface) => {
    const exportLink = `transaction/downloadTransactions/vendor/${user?.vendor.id}`;
    try {
      await DOWNLOAD(exportLink, user?.vendor.name);
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <MainLayout>
      <TransactionProvider
        render={({
          state,
          items,
          previous,
          next,
          tableHeadings,
          closeModal,
          nullClick,
          onSearch,
          total,
          totalPages,
          user,
          getValues,
          clearTag, 
          applyFilter
        }: any) => {
          const { product, station, state_, startDate, endDate, analytics, applying } = state
          return (
            <ContentLayout title={"Sales"}>
              <div className="d-flex">
                <CardContainer>
                  <Card
                    className={"blue-card width-adjusted"}
                    title={
                      analytics?.numberOfSales ?? <Spinner className="spinner-small spinner-white"/>
                    }
                    description={"Number of sales"}
                  />
                  <Card
                    className={"yellow-card width-adjusted"}
                    title={
                      analytics?.totalNumberOfLitres ?? <Spinner className="spinner-small spinner-white" />
                    }
                    description={"Total litres sold"}
                  />
                  <Card
                    className={"green-card width-adjusted"}
                    title={
                      `USD ${analytics?.totalRevenue?.['USD'] || 0}` ?? <Spinner className="spinner-small spinner-white" />
                    }
                    description={"Total revenue"}
                  />
                </CardContainer>
                <Filter 
                  className={"filterBox justify-space-between"}  
                  station={station}
                  product={product}
                  state_={state_}
                  startDate={startDate}
                  endDate={endDate}
                  getValues={getValues}
                  clearTag={clearTag}
                  vendor={user?.vendor}
                  applyFilter={applyFilter}
                  applying={applying}
                />
              
              </div>
              {state.show ? (
                <Modal onNullClick={nullClick} onClick={closeModal}>
                  <div className="transaction-modal">
                    <div className="user-details">
                      <div className="user-icon">
                        <img
                          className="user-svg"
                          src={require("../../assets/images/user.svg")}
                          alt=""
                        />
                      </div>
                      <div className="flex justify-center mt-2 pb-2">
                        {state.transaction.user
                          ? state.transaction.user.firstName
                          : ""}{" "}
                        {state.transaction.user
                          ? state.transaction.user.lastName
                          : ""}
                      </div>
                      <div className="flex justify-center" />
                      <div className="partnerDetails">
                        <div className="d-flex justify-space-between">
                          <i className="fas fa-phone" />
                          <span>
                            {state.transaction.user
                              ? state.transaction.user.mobile
                              : ""}
                          </span>
                        </div>
                        <div className="d-flex justify-space-between">
                          <strong>Truck No:</strong>
                          <span>
                            {state.transaction.activeTrip
                              ? state.transaction.activeTrip.truckNumber
                              : "N/A"}
                          </span>
                        </div>

                        <div className="d-flex justify-space-between">
                          <strong>Partner:</strong>
                          <span>
                            {state.transaction.activeTrip
                              ? state.transaction.activeTrip.partnerName
                              : "N/A"}
                          </span>
                        </div>

                        <div className="d-flex justify-space-between">
                          <strong>TripId: </strong>
                          <span>
                            {state.transaction.activeTrip
                              ? state.transaction.activeTrip.readableTripId
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className={"_footer"}>
                        Sold by:{" "}
                        {state.transaction.attendant
                          ? state.transaction.attendant.name
                          : ""}
                      </div>
                    </div>
                    <div className="transaction-details">
                      <div className="trans-detail">
                        <div className="flex flex-column">
                          <strong>Product Name </strong>
                          <small>
                            {state.transaction.productName
                              ? state.transaction.productName
                              : ""}
                          </small>
                        </div>
                      </div>

                      <div className="trans-detail">
                        <div className="flex flex-column">
                          <strong>Quantity</strong>
                          <small>
                            {state.transaction.quantity
                              ? state.transaction.quantity
                              : ""}
                          </small>
                        </div>
                      </div>

                      <div className="trans-detail">
                        <div className="flex flex-column">
                          <strong>Value</strong>
                          <small>
                            {new Intl.NumberFormat("en-NG", {
                              style: "currency",
                              currency: "NGN",
                            }).format(
                              state.transaction.value
                                ? state.transaction.value
                                : "0"
                            )}
                          </small>
                        </div>
                      </div>

                      <div className="trans-detail padding-bottom-30">
                        <div className="flex flex-column">
                          <strong>User Paid</strong>
                          <small>
                            {new Intl.NumberFormat("en-NG", {
                              style: "currency",
                              currency: "NGN",
                            }).format(
                              state.transaction.userPay
                                ? state.transaction.userPay
                                : "0"
                            )}
                          </small>
                        </div>
                      </div>
                      <div
                        className="horizontal__line"
                        style={{ marginLeft: 0 }}
                      />
                      <div className="trans-detail">
                        <div className="flex">
                          <strong>Reference</strong>
                          <small style={{ marginLeft: "2em" }}>
                            {state.transaction.reference
                              ? state.transaction.reference
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="trans-detail">
                        <div className="flex">
                          <strong>Status</strong>
                          <small
                            className={
                              state.transaction &&
                              state.transaction.status === "Pending"
                                ? "text--yellow"
                                : state.transaction &&
                                  state.transaction.status === "Successful"
                                ? "text--green"
                                : ""
                            }
                            style={{ marginLeft: "4em" }}
                          >
                            {state.transaction.status
                              ? state.transaction.status
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              ) : (
                ""
              )}

              <Table
                showHelmet={true}
                headings={tableHeadings}
                paginated={totalPages > 1}
                loading={state.loading}
                // onSearchChange={onChange}
                currentPage={state.currentPage}
                totalPages={totalPages || 1}
                onPrev={previous}
                onNext={next}
                onExportList={() => downloadSales(user)}
                onSearch={onSearch}
                searchTerm={state.searchTerm}
                // exportLink={`${process.env.REACT_APP_URL}transaction/downloadTransactions/vendor/${user.vendor.id}`}
                data={
                  state.searchTerm
                    ? state.searchResults
                    : items && items.transactions && items.transactions.length
                    ? items.transactions
                    : []
                }
              />
            </ContentLayout>
          );
        }}
      />
    </MainLayout>
  );
};
export default Transactions;
