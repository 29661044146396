import * as React from "react";
import MainLayout from "../../../shared/components/MainLayout/MainLayout";
import ContentLayout from "../../../shared/components/ContentLayout/ContentLayout";
import Form from "../../../shared/components/Form/Form";
import Step from "../../../shared/components/Step/Step";
import "../../../shared/components/Dropdown/Dropdown.scss";
import UsersProvider from "../UsersProvider/UsersProvider";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { formatWord, sortArray } from "../../../shared/utils/fx";

interface UserProps {
  history: {
    push: (params: string) => void;
  };
};

function AddUser(props: UserProps) {
  return (
    <>
      <MainLayout>
        <UsersProvider
          render={({
            state,
            setState,
            setInputValues,
            inputValues,
            getVendor,
            handleAddUser,
            handleChange,
            searchPartner,
            countryList,
            vendors,
            err,
            handleOnChange,
          }: any) => {
            return (
              <ContentLayout title={"Add User"}>
                <div className="right" />
                <div>
                  <Step steps={[true]} />
                  <Form
                    onCancel={() => props.history.push("/user-management")}
                    title={"Add User"}
                    onSubmit={(e) => handleAddUser(e)}
                    footer={
                      <Form.Footer className={"justify-center"}>
                        <Form.Save title={"Add User"} onSave={handleAddUser} />
                      </Form.Footer>
                    }
                  >
                    {/* <Form.Select
                      name="vendorId"
                      value={inputValues.vendorId}
                      label={"Vendor"}
                      onChange={(e) => getVendor({ vendorId: e.target.value })}
                    >
                      <option></option>
                      {vendors &&
                        vendors.map((vendor: any) => (
                          <option key={vendor.id} value={vendor.id}>
                            {vendor.name}
                          </option>
                        ))}
                    </Form.Select> */}
                    <div className="d-flex align-center">
                      <Form.Input
                        type={"text"}
                        className={"mr-1"}
                        name="firstName"
                        value={inputValues.firstName}
                        onChange={handleChange}
                        label={"First name"}
                      />
                      <Form.Input
                        type={"text"}
                        name="lastName"
                        value={inputValues.lastName}
                        label={"Last name"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="d-flex align-center">
                      <Form.Input
                        type={"text"}
                        name="email"
                        className={"mr-1"}
                        value={inputValues.email}
                        label={"Email address "}
                        onChange={handleChange}
                      />
                      <Form.Select
                        name="role"
                        value={inputValues.role}
                        label={"Role "}
                        onChange={handleChange}
                      >
                        <option value=""></option>
                        <option value="admin">Admin</option>
                        <option value="manager">Manager</option>
                        <option value="attendant">Attendant</option>
                      </Form.Select>
                    </div>
                    <Form.Select
                      name="country"
                      value={inputValues.country}
                      label={"Country"}
                      className="mr-1"
                      onChange={handleChange}
                    >
                      <option></option>

                      {countryList
                        ? sortArray(countryList.countries, 'country').map((country: any) => (
                            <option
                              key={country.countryCode}
                              value={country.country}
                            >
                              {formatWord(country.country)}
                            </option>
                          ))
                        : ""}
                    </Form.Select>
                    <Form.Input label={"Mobile Number"} onChange={handleChange}>
                      <ReactPhoneInput
                        // preferredCountries={["ng", "gh", "ke", "tg", "bf"]}
                        value={state.mobile}
                        country={countryList ? countryList?.countries.find((county: {[key: string]: any}) => county.country.toLowerCase() === inputValues.country)?.countryCode.toLowerCase() : 'ng'}
                        onChange={handleOnChange}
                      />
                    </Form.Input>
                    <div className="d-flex align-center">
                      <Form.Input
                        type={"password"}
                        name="password"
                        value={inputValues.password}
                        onChange={handleChange}
                        className={"mr-1"}
                        label={"Password"}
                      />
                      <Form.Input
                        type={"password"}
                        name="confirmPassword"
                        value={inputValues.confirmPassword}
                        label={"Confirm Password"}
                        onChange={handleChange}
                        error={err}
                      />
                    </div>
                  </Form>
                </div>
              </ContentLayout>
            );
          }}
        />
      </MainLayout>
    </>
  );
}

export default AddUser;
