import {ADD_VOUCHER, SET_VOUCHERS} from "../../types/types";

type InitialState = {
    vouchers: {
        vouchers: any;
    };
};

const initialState: InitialState = {
    vouchers: {
        vouchers: [],
    },
};

type Action = {
    type: "SET_VOUCHERS" | "ADD_VOUCHER";
    vouchers: any[];
    voucher: any;
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_VOUCHERS:
            return {
                ...state,
                vouchers: action.vouchers,
            };

        case ADD_VOUCHER:
            console.log(state.vouchers);
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    vouchers: [{...action.voucher}, ...state.vouchers.vouchers],
                },
            };
        default:
            return state;
    }
};
