import {
  SET_TRANSACTIONS,
  SET_LIMIT,
  SET_CURRENT_PAGE,
} from "../../types/types";
import { GET } from "../../../core/config/Api";

export const setTransaction = (transactions: any) => {
  return {
    type: SET_TRANSACTIONS,
    transactions,
  };
};

export const setLimit = (limit: any) => {
  return {
    type: SET_LIMIT,
    limit,
  };
};

export const setCurrentPage = (currentPage: any) => {
  return {
    type: SET_CURRENT_PAGE,
    currentPage,
  };
};

type DispatchType = (e: any) => void;
export const fetchTransactions = (endpoint: string) => (
  dispatch: DispatchType
) => {
  return GET(endpoint).then((res) => {
    dispatch(setTransaction(res.data.data));
  });
};
