import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchTransactions,
  setCurrentPage,
} from "../../../store/actions/transaction/transaction";
import AuthProvider from "../../../shared/providers/AuthProvider/AuthProvider";
import { buildQuery, formatNumber, handleSearch } from "../../../shared/utils/fx";
import { objInterface } from '../../../shared/utils/interfaces';
import { GET } from '../../../core/config/Api';

interface ITransactionProviderProps {
  currentPage: number;
  limit: string;
  fetchTransactions: (url: string) => Promise<any>;
  setCurrentPage: any;
  render: any;
  total: any;
  items: any;
  totalPages: any;
  user: { [key: string]: any }
};
const textColor: { [key: string]: string } = {
  Successful: "green",
  Failed: "red",
  Pending: "yellow"
}

class TransactionProvider extends Component<ITransactionProviderProps> {
  state = {
    loading: false,
    currentPage: this.props.currentPage,
    limit: this.props.limit,
    searchTerm: "",
    search: false,
    show: false,
    transaction: {},
    searchResults: [],
    user: this.props.user,
    product: '',
    station: '',
    state_: '',
    startDate: '',
    endDate: '',
    analytics: {},
    applying: false
  };

  static defaultProps: objInterface = {
    user: JSON.parse(localStorage?.getItem('koboCareVendor_user') ?? '{}')
  }

  async componentDidMount() {
    document.title = "Kobo Care | Sales";
    this.setState({ loading: true });

    await this.props
      .fetchTransactions(
        `transaction?vendorId=${this.props.user?.vendor?.id}&limit=${this.state.limit}&page=${this.state.currentPage}`
      )
      .then((res: any) => {
        this.setState({ loading: false });
        GET(`transaction/analytics/${this.props.user?.vendor?.id}`)
          .then((resAnal: any) => {
            this.setState({ analytics: resAnal.data.data });
          })
      })
      .catch((err: Error) => {
        this.setState({ loading: false });
      });
  }


  applyFillter = async () => {
    if (this.state.product ||
      this.state.station
      || (this.state.startDate && this.state.endDate)
    ) {
      const query = buildQuery({
        productId: this.state.product,
        stationId: this.state.station,
        startDate: this.state.startDate,
        endDate: this.state.endDate
        // country: this.state.user.country

      })
      this.setState({ applying: true});
      GET(`transaction/analytics/${this.props.user?.vendor?.id}?${query}`)
        .then((resAnal: any) => {
          this.setState({ analytics: resAnal.data.data, applying: false });
        }).then(async() => {
          this.setState({ loading: true });
          await this.props
        .fetchTransactions(
          `transaction?vendorId=${this.props.user?.vendor?.id}&limit=${this.state.limit}&page=${this.state.currentPage}&${query}`
        ).then((res_:any) => {
          this.setState({ loading: false });

        })
        }).catch(err => {
          this.setState({ applying: false});
        })

    }
  }

  showModal = (e: any, transaction: any) => {
    this.setState({ show: !this.state.show, transaction });
  };

  closeModal = () => {
    this.setState({ show: false });
  };

  nullClick = (e: any) => {
    e.stopPropagation();
  };

  onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value, search: true, loading: true }, () => {
      if (this.state.searchTerm) {
        const results = handleSearch(value, this.props.items.transactions);
        this.setState({ searchResults: results, loading: false });

      } else {
        this.setState({ searchResults: [], loading: false });
      }

    });
  };

  next = (e: HTMLElement) => {
    const { currentPage, search } = this.state;

    if (currentPage < this.props.totalPages) {
      this.setState({ currentPage: currentPage + 1, loading: true }, () => {
        if (!search) {
          this.props
            .fetchTransactions(
              `transaction?vendorId=${this.state.user?.vendor?.id}&limit=${this.state.limit}&page=${this.state.currentPage}`
            )
            .then((res: any) => {
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              // handleError(err);
              this.setState({ loading: false });
            });
        } else {
          if (this.state.searchTerm.length > 0) {
            this.props
              .fetchTransactions(
                `transaction/search?vendor=${this.state.user?.vendor?.id}&searchTerm=${this.state.searchTerm}&limit=${this.state.limit}&page=${this.state.currentPage}`
              )
              .then((res: any) => {
                this.setState({ loading: false });
              })
              .catch((err: any) => {
                // handleError(err)
                this.setState({ loading: false });
              });
          } else {
            this.props
              .fetchTransactions(
                `transaction?vendorId=${this.state.user?.vendor?.id}&limit=${this.state.limit}&page=${this.state.currentPage}`
              )
              .then((res: any) => {
                this.setState({ loading: false });
              })
              .catch((err: any) => {
                // handleError(err);
                this.setState({ loading: false });
              });
          }
        }
      });
    }
  };

  previous = (e: HTMLElement) => {

    const { currentPage, search } = this.state;
    if (currentPage >= 2) {
      this.setState({ currentPage: currentPage - 1, loading: true }, () => {
        if (!search) {
          this.props
            .fetchTransactions(
              `transaction?vendorId=${this.state.user?.vendor?.id}&limit=${this.state.limit}&page=${this.state.currentPage}`
            )
            .then((res: any) => {
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              this.setState({ loading: false });
            });
        } else {
          if (this.state.searchTerm.length > 0) {
            this.props
              .fetchTransactions(
                `transaction/search?vendorId=${this.state.user?.vendor?.id}&searchTerm=${this.state.searchTerm}&limit=${this.state.limit}&page=${this.state.currentPage}`
              )
              .then((res: any) => {
                this.setState({ loading: false });
              })
              .catch((err: any) => {
                this.setState({ loading: false });
              });
          } else {
            this.props
              .fetchTransactions(
                `transaction?vendorId=${this.state.user?.vendor?.id}&limit=${this.state.limit}&page=${this.state.currentPage}`
              )
              .then((res: any) => {
                this.setState({ loading: false });
              })
              .catch((err: any) => {
                this.setState({ loading: false });
              });
          }
        }
      });
    }
  };

  getValues = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  clearTag = async(val: string, val_?: string, val1?: string, val1_?: string) => {
    if (val_) {
      this.setState({ [val_]: '' });
    }
    if (val) {
      this.setState({ [val]: '' });
    }

    if (val1_) {
      this.setState({ [val1_]: '' });
    }
    if (val1) {
      this.setState({ [val1]: '' });
    }
    await this.props
      .fetchTransactions(
        `transaction?vendorId=${this.props.user?.vendor?.id}&limit=${this.state.limit}&page=${this.state.currentPage}`
      )
      .then((res: any) => {
        this.setState({ loading: false });
        GET(`transaction/analytics/${this.props.user?.vendor?.id}`)
          .then((resAnal: any) => {
            this.setState({ analytics: resAnal.data.data });
          })
      })
      .catch((err: Error) => {
        this.setState({ loading: false });
      });
  }

  render() {
    const tableHeadings: Array<{
      key: string,
      title: string,
      formatter?: (data: { [key: string]: any }) => React.ReactNode
    }
    > = [
        {
          key: "voucher",
          title: "Voucher number",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                <p className="tableText">{data.voucher}</p>

              </>
            );
          },
        },
        {
          key: "date",
          title: "Date/time",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                <p className="blue small ">
                  {new Date(data.createdDate).toLocaleDateString()} -{" "}
                  {new Date(data.createdDate).toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </p>
              </>
            );
          },
        },
        {
          key: "productName",
          title: "Product",
        },
        {
          key: "walletCode",
          title: "User",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                <p className="tableText">
                  {data.user.firstName} {data.user.lastName}
                </p>
                {/* <p
                onClick={(e) => this.showModal(e, data)}
                className="blue small pointer text-uppercase"
              >
                {data?.walletCode?.replace(/.(?=.{4})/g, "x")}
              </p> */}
              </>
            );
          },
        },
        {
          key: "quantity",
          title: "Quantity",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                {/* <div className="tableText">{data.value}</div> */}
                <div className="blue small">
                  {data.quantity} {data.productUnit}
                </div>
              </>
            );
          },
        },
        {
          key: "rate",
          title: "Product rate",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                <p className={"min-width-100"}>
                  {data?.currency} {formatNumber(data?.rate)}
                </p>
              </>
            );
          },
        },


        {
          key: "station.name",
          title: "Station",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                <div className="tableText ">{data.station.name}</div>
                <div className="blue small ">
                  {data.station.location}
                </div>
              </>
            );
          },
        },

        {
          key: "type",
          title: "Redemption",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                <div className="tableText">{data.type}</div>
                {/* <div className="blue small">{data.discount}</div> */}
              </>
            );
          },
        },
        {
          key: "value",
          title: "Amount",
          formatter: (data: { [key: string]: any }) => {
            return (
              <>
                <p className={"min-width-100"}>
                  {data?.currency} {formatNumber(data?.value)}
                </p>
              </>
            );
          },
        },
        // {
        //   key: "gtv",
        //   title: "Gtv",
        //   formatter: (data: { [key: string]: any }) => {
        //     return (
        //       <>
        //         <p className={"min-width-100"}>
        //           {naira} {data.gtv ? formatNumber(data.gtv) : "0"}
        //         </p>
        //       </>
        //     );
        //   },
        // },
        {
          key: "status",
          title: "Payment Status",
          formatter: (data: { [key: string]: any }) => {
            return (
              <p className="status">
                <span
                  className={
                    data.status === "Successful"
                      ? "statusSuccess"
                      : data.status === "Pending"
                        ? "statusPending"
                        : "statusFail"
                  }
                />{" "}
              &nbsp; &nbsp;
                <span className={textColor[data?.status]}>{data.status}</span>
              </p>
            );
          },
        },
      ];

    return this.props.render({
      state: this.state,
      previous: this.previous,
      next: this.next,
      showModal: this.showModal,
      closeModal: this.closeModal,
      nullClick: this.nullClick,
      onSearch: this.onSearch,
      tableHeadings,
      items: this.props.items,
      currentPage: this.props.currentPage,
      limit: this.props.limit,
      total: this.props.total,
      totalPages: this.props.totalPages,
      user: this.props.user,
      getValues: this.getValues,
      clearTag: this.clearTag,
      applyFilter: this.applyFillter
    });
  }
}

const mapStateToProps = (state: objInterface) => {
  return {
    items: state.transaction.transactions,
    currentPage: state.transaction.transactions.currentPage,
    limit: state.transaction.transactions.limit,
    total: state.transaction.transactions.total,
    totalPages: state.transaction.transactions.totalPages,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, { fetchTransactions, setCurrentPage })(
  AuthProvider(TransactionProvider)
);
