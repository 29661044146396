import {
  ACCEPT_PRICE_APPROVAL,
  ADD_APPROVAL,
  REJECT_APPROVAL,
  REJECT_PRICE_APPROVAL,
  SET_APPROVALS,
} from "../../types/types";

type InitialState = {
  approvals: {
    products: any[];
    limit: number;
    currentPage: number;
  };
};

const initialState: InitialState = {
  approvals: {
    products: [],
    limit: 30,
    currentPage: 1,
  },
};

type Action = {
  type?:
    | "SET_APPROVALS"
    | "ADD_APPROVAL"
    | "REJECT_APPROVAL"
    | "REJECT_PRICE_APPROVAL"
    | "ACCEPT_PRICE_APPROVAL";
  accept?: any;
  approval?: any;
  approvals?: any[];
  reject?: any;
};
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_APPROVALS:
      return {
        ...state,
        approvals: action.approvals,
      };

    case ADD_APPROVAL:
      return {
        ...state,
        approvals: {
          ...state.approvals,
          contracts: state.approvals.products.filter(
            (approval) => approval._id !== action.approval._id
          ),
        },
      };

    case REJECT_APPROVAL:
      return {
        ...state,
        approvals: {
          ...state.approvals,
          approvals: state.approvals.products.filter(
            (approval) => approval._id !== action.reject._id
          ),
        },
      };

    case ACCEPT_PRICE_APPROVAL:
      return {
        ...state,
        approvals: {
          ...state.approvals,
          approvals: state.approvals.products.filter(
            (accept) => accept._id !== action.accept._id
          ),
        },
      };

    case REJECT_PRICE_APPROVAL:
      return {
        ...state,
        approvals: {
          ...state.approvals,
          approvals: state.approvals.products.filter(
            (reject) => reject.rateApproval._id !== action.reject.approval._id
          ),
        },
      };

    default:
      return state;
  }
};
