import React, { ReactNode, useState, useCallback, useEffect } from "react";
import "./Filter.scss";
import { GET } from '../../../core/config/Api';
import { objInterface } from "../../utils/interfaces";

type formEvents = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>;

interface FilterProps {
    className?: string;
    children?: ReactNode;
    station: string;
    state_: string;
    product: string;
    startDate: string;
    endDate: string;
    getValues: (e: formEvents) => void;
    clearTag: (val: string, val_?: string, val1?: string, val1_?: string) => void;
    vendor: objInterface;
    applyFilter: () => void;
    applying: boolean;
};

const Filter = ({ 
        className, 
        station, 
        product, 
        startDate, 
        endDate, 
        getValues,
        clearTag,
        applyFilter,
        applying,
        vendor }: FilterProps) => {
    const [dateInput, setDateInput] = useState(false);
    const [productInput, setProductInput] = useState(false);
    const [stationInput, setStationInput] = useState(false);
    const [products, setProducts] = useState<Array<objInterface>>([]);
    const [stations, setStations] = useState<Array<objInterface>>([]);

    const getProducts = useCallback(
        async () => {
            try {
                const prods = await GET(`product/vendor/${vendor.id}/all`)
                setProducts(prods.data.data.products);
            } catch (error) {

            }
        },
        [vendor.id]
    )

    useEffect(() => {
        getProducts()
    }, [getProducts])

    const getStations = useCallback(
        async () => {
            try {
                const stations= await GET(`vendor/${vendor.id}/stations`);
                setStations(stations.data.data.stations);
            } catch (error) {

            }
        },
        [vendor.id]
    )

    useEffect(() => {
        getStations()
    }, [getStations])


    const showDate = () => {
        setDateInput(!dateInput);
        setProductInput(false);
        setStationInput(false);

    }

    const showProduct = () => {
        setProductInput(!productInput);
        setDateInput(false);
        setStationInput(false);
    }

    const showStation = () => {
        setStationInput(!stationInput);
        setProductInput(false);
        setDateInput(false);
        
    }

    const clearFilter = () => {
        clearTag('startDate', 'endDate', 'product', 'station'); 
       
    }

    return (
        <div className={`${className || ""}`}>
            <div className="filterBox_buttons">
                <div className="filters">
                    <p>Filter by:</p>
                    <button title="date" onClick={showDate}>Date</button>
                    <button title="product" disabled={station !== ''} onClick={showProduct}>Product</button>
                    <button title="station" disabled={product !== ''} onClick={showStation}>Station</button>
                    {/* <button title="state" onClick={showState}>State</button> */}
                </div>
                <div className="input-form">
                    {productInput &&
                        <select required value={product} name="product" onChange={e => getValues(e)}>
                            <option value="" hidden>Select Product</option>
                            {products.map(({productId, name}) => 
                                <option key={productId} value={productId}>{name}</option>
                            )}
                        </select>
                    }
                    {dateInput &&
                        <div>
                            <input 
                                type="date" 
                                name="startDate" 
                                placeholder="From" 
                                value={startDate}
                                onChange={e => getValues(e)} 
                            />
                            <span> - </span>
                            <input 
                                type="date" 
                                name="endDate" 
                                placeholder="To" 
                                min={startDate}
                                value={endDate}
                                onChange={e => getValues(e)}  
                            />
                        </div>
                    }
                    {/* {stateInput &&
                        <div>
                            <select required>
                                <option value="" hidden>Select Country</option>
                            </select>
                            <span> - </span>
                            <select required>
                                <option value="" hidden>Select state</option>
                            </select>
                        </div>
                    } */}
                    {stationInput &&
                        <select required value={station} name="station" onChange={e => getValues(e)} >
                            <option value="" hidden>Select Station</option>
                            {stations.map(({id, name}) => 
                                <option key={id} value={id}>{name}</option>
                            )}
                        </select>
                    }
                </div>
            </div>
            <div className="filterBox_content">
                {(startDate !== '' && endDate !== '') && <div>{startDate} - {endDate} <span role="button" onClick={() => clearTag('startDate', 'endDate') }>&#x2715;</span></div>}
                {product !== '' && <div>{products?.find((prod: any) => prod.productId === product)?.name} <span role="button" onClick={() => clearTag('product') }>&#x2715;</span></div>}
                {station !== '' && <div>{stations?.find(singleStation => singleStation.id === +station)?.name} <span role="button" onClick={() => clearTag('station') }>&#x2715;</span></div>}
                {/* {state_ !== '' && <div>This is a state label <span>&#x2715;</span></div>} */}
                {((startDate && endDate) || product || station) &&
                    <div className="filterBtns">
                        <button onClick={applyFilter}>{applying ? '....' : 'APPLY'}</button>
                        <button className="clear" onClick={clearFilter}>CLEAR FILTER</button>
                    </div>}

            </div>
        </div>
    );
};

export default Filter;

