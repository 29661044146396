import React, { ReactNode } from "react";
import "./CardContainer.scss";

type CardContainerProps = {
  className?: string;
  children: ReactNode;
};
const CardContainer = (props: CardContainerProps) => {
  return (
    <div className={`cardContainer ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

export default CardContainer;
