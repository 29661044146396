import * as React from "react";
import './Spinner.scss';

interface SpinnerProps {
    className?: string;
};

function Spinner({ className }:SpinnerProps) {
    return (
        <div className={`spinner ${className}`}/>
    );
}

export default Spinner;
