import React, { ReactNode } from "react";
import "./MainLayout.scss";
import SideMenu from "../SideMenu/SideMenu";
import AuthProvider from "../../providers/AuthProvider/AuthProvider";

interface MProps {
  children: ReactNode;
  className?: string;
  handleLogout?: () => void;
  user: {[key: string]:  any };
};
function MainLayout(props: MProps) {
  const { user } = props;
  return (
    <div className={`main-layout ${props.className || ""}`}>
      <SideMenu>
        <SideMenu.Header title={user?.vendor.name} />
        <SideMenu.List>
          {/* <SideMenu.Item
            icon={require("../../../assets/images/dashboard.svg")}
            title={"Dashboard"}
            link={"/dashboard"}
          />
           */}
          <SideMenu.SubHeader title={"OPERATIONS"} />
          <SideMenu.Item
            icon={require("../../../assets/images/transaction.svg")}
            title={"Sales"}
            link={"/sales"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/vendor.svg")}
            title={"Stations"}
            link={`/stations`}
          />
          
          {/* <SideMenu.Item
            icon={require("../../../assets/images/trip.svg")}
            title={"Leads"}
            link={"/truck-management"}
          /> */}
          {/* <SideMenu.Item
            icon={require("../../../assets/images/monetization-user-bag.svg")}
            title={"Partners"}
            link={"/onboarded-partners"}
          />

          <SideMenu.SubHeader title={"CARD MANAGEMENT"} />
          <SideMenu.Item
            icon={require("../../../assets/images/wallet.svg")}
            title={"Card"}
            link={"/card-management"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/top-up-approval.svg")}
            title={"Top up Approvals"}
            link={"/top-up-approvals"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/ticket.svg")}
            title={"Voucher"}
            link={"/vouchers"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/accounting-coins-stack-1.svg")}
            title={"Cash Incentives"}
            link={"/cash-incentives"}
          />
          <SideMenu.SubHeader title={"PRODUCT"} />
          <SideMenu.Item
            icon={require("../../../assets/images/product.svg")}
            title={"Products"}
            link={"/products"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/product-approval.svg")}
            title={"Approvals"}
            link={"/product-approvals"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/contract.svg")}
            title={"Contract"}
            link={"/contracts"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/tag.svg")}
            title={"Price Approvals"}
            link={"/price-approvals"}
          />
          <SideMenu.SubHeader title={"RECONCILIATION"} />
          <SideMenu.Item
            icon={require("../../../assets/images/folder.svg")}
            title={"Vendor"}
            link={"/vendor-reconciliations"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/folder.svg")}
            title={"Product"}
            link={"/product-reconciliation"}
          /> */}
          {/* <SideMenu.Item
            icon={require("../../../assets/images/saving-bank.svg")}
            title={"Bank"}
            link={"/bank"}
          /> */}
          {/* <SideMenu.Item
            icon={require("../../../assets/images/trip.svg")}
            title={"Trip Transaction"}
            link={"/trip-transactions"}
          /> */}
          <SideMenu.Item
            icon={require("../../../assets/images/product.svg")}
            title={"Products"}
            link={"/products"}
          />
          {/* <SideMenu.SubHeader title={"USER"} /> */}
          
          <SideMenu.SubHeader title={"SETTINGS"} />
          <SideMenu.Item
            icon={require("../../../assets/images/user.svg")}
            title={"User Management"}
            link={"/user-management"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/user.svg")}
            title={"Profile"}
            link={"/profile"}
          />
          <SideMenu.Item
            icon={require("../../../assets/images/logout.svg")}
            title={"Sign out"}
            onClick={props.handleLogout}
          />
        </SideMenu.List>
      </SideMenu>
      {props.children}
    </div>
  );
}

export default AuthProvider(MainLayout);
