import { ADD_PAYMENT, SET_PAYMENTS } from "../../types/types";

type InitialState = {
  payments: {
    payments: any[];
  };
};

const initialState: InitialState = {
  payments: {
    payments: [],
  },
};
type Action = {
  type: "ADD_PAYMENT" | "SET_PAYMENTS";
  card?: any;
  payment?: any;
  payments?: any[];
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return {
        ...state,
        payments: action.payments,
      };
    case ADD_PAYMENT:
      return {
        ...state,
        payments: {
          ...state.payments,
          payments: [{ ...action.payment }, ...state.payments.payments],
        },
      };
    default:
      return state;
  }
};
