import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthProvider from "../../../shared/providers/AuthProvider/AuthProvider";
import { getDash, mobileNum } from "../../../shared/utils/fx";
import { GET, POST, PUT } from "../../../core/config/Api";
import { withToastManager } from "react-toast-notifications";
import { fetchUsers, addUser } from "../../../store/actions/user/user";
import { useHistory } from 'react-router';
import { objInterface } from '../../../shared/utils/interfaces';

interface ProviderProps {
  currentPage: any;
  limit: string;
  fetchUsers: (url: string) => Promise<any>;
  render: any;
  total: any;
  items: any;
  totalPages: any;
  addUser?: any;
  toastManager?: any;
  vendor: {[key: string]: any}
};

function UsersProvider(props: ProviderProps) {
  const { items, currentPage, limit, total, totalPages, fetchUsers, vendor } = props;
  const [countryList, setCountryList] = useState("");
  const [vendors, setVendors] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [_show, _setShow] = useState(false);
  const [actionBox, setActionBox] = useState(false);
  const [, setBoxId] = useState("");
  const [err, setErr] = useState("");
  const [state, setState] = useState({
    loading: false,
    totalPages,
    limit,
    currentPage,
    searchTerm: "",
  });
  const { push } = useHistory<{[key: string]: any}>();

  const [inputValues, setInputValues] = useState({
    vendor: "",
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    country: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    oldPassword: "",
    newPassword: "",
    vendorId: "",
    vendorName: "",
  });

  const toastMessage = (message: string, type: string) => {
    
    props.toastManager.add(message, {
      appearance: type,
    });
  };
  useEffect(() => {
    document.title = "Kobo Care | Users";
    // setState(state => ({ ...state, currentPage: 1 }));

    setLoading(true);
    fetchUsers(`vendor/${vendor?.id}/users?limit=${limit}&page=${state.currentPage}`)
      .then((res: any) => {
        setLoading(false);
        GET(`vendor`).then((res) => {
          setLoading(false);
          setVendors(res.data.data.vendors);
        });
      })
      .catch((err: any) => {
        setLoading(false);
      });
  }, [fetchUsers, limit, state.currentPage, vendor]);

  useEffect(() => {
    GET(`route/country`).then((res: any) => {
      setCountryList(res.data.data);
    });
  }, []);

  const closeModal = () => {
    setShow(false);
    _setShow(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleOnChange = (value: any) => {
    setInputValues({ ...inputValues, mobile: value });
  };

  const getVendor = ({ vendorId }: { vendorId: string }) => {
    //@ts-ignore

    let selectedVendor = vendors.filter(
      (vendor: any) => vendor.id === parseInt(vendorId, 10)
    )[0];
    setInputValues({
      ...inputValues,
      vendorName: selectedVendor.name,
      vendorId: selectedVendor.id,
    });
  };
  const handleAddUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const data = {
      first_name: inputValues.firstName,
      last_name: inputValues.lastName,
      role: inputValues.role,
      email: inputValues.email,
      mobile:
        inputValues.mobile.charAt(5) === "0"
          ? (inputValues.mobile = `+${mobileNum(
              inputValues.mobile.replace(inputValues.mobile.charAt(5), "")
            )}`)
          : `+${mobileNum(inputValues.mobile)}`,
      country: inputValues.country,
      secret: inputValues.password,
      confirm_secret: inputValues.confirmPassword,
    };

    if (inputValues.password !== inputValues.confirmPassword) {
      setErr("Your password must match");

      setTimeout(() => {
        setErr("");
      }, 3000);
    } else {
      props
        .addUser(`vendor/${props.vendor.id}/user`, data)
        .then((res: any) => {
          toastMessage("User successfully created", "success");
          push('/user-management');
          setInputValues({
            ...inputValues,
            vendor: "",
            firstName: "",
            lastName: "",
            role: "",
            email: "",
            country: "",
            mobile: "",
            password: "",
            confirmPassword: "",
            vendorId: "",
            vendorName: "",
          });
          
        })
        .catch((err: any) => {
          console.log(err)
            toastMessage(err?.response?.data?.message ?? 'Something wrong has happened', "error");

        });
    }
  };

  const handleOptions = (data: any) => {
    setActionBox(!actionBox);
    setUser(data);
    setBoxId(data.id);
  };

  const editUser = (data: any) => {
      setShow(true);
      setUser(data);
  }
  const tableHeadings: any[] = [
    {
      key: "userId",
      title: "User ID",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText">
              {data && data.user_code ? data.user_code : getDash()}
            </div>
          </>
        );
      },
    },
    {
      key: "name",
      title: "Name",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText">
              {data 
                ? data.first_name + " " + data.last_name
                : getDash()}
            </div>
          </>
        );
      },
    },
    {
      key: "asset",
      title: "Email",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText text-left">
              {data  ? data.email : getDash()}
            </div>
          </>
        );
      },
    },
    {
      key: "mobile",
      title: "Phone Number",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText text-left">
              {data ? data.mobile : getDash()}
            </div>
          </>
        );
      },
    },

    {
      key: "role",
      title: "Role",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText text-left"> {data.role ?? getDash()}</div>
          </>
        );
      },
    },

    {
      key: "options",
      title: "Options",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText text-left">
              <div className="position-relative boxContainer">
                <img
                  onClick={() => handleOptions(data)}
                  src={require("../../../assets/images/option.svg")}
                  alt=""
                />
                {vendor.admin.role.toLowerCase() !== 'attendant' &&
                <div className="action-box">
                  <div
                    className="action-item"
                    onClick={() => editUser(data)}
                  >
                    Edit User
                  </div>
                
                </div>}
                {/* )} */}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const handleEditUser = (e: React.ChangeEvent<HTMLInputElement>, user: objInterface) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      first_name: inputValues.firstName || user.first_name,
      last_name: inputValues.lastName || user.last_name,
      role: inputValues.role || user.role,
      email: inputValues.email || user.email,
      mobile:
      inputValues.mobile ?
        inputValues.mobile.charAt(5) === "0"
          ? (inputValues.mobile = `+${mobileNum(
              inputValues.mobile.replace(inputValues.mobile.charAt(5), "")
            )}`)
          : `+${mobileNum(inputValues.mobile)}` : user.mobile,
    };
    
    POST(`user/${user.auth_id}/detail `, data)
      .then((res: any) => {
        toastMessage(res.data.message, "success");
        setLoading(false);

        setInputValues({
          ...inputValues,
          firstName: "",
          lastName: "",
          role: "",
          email: "",
          mobile: "",
          password: "",
        });
        setShow(false);
        fetchUsers(`vendor/${vendor?.id}/users?limit=${limit}&page=${state.currentPage}`)
        window.location.reload();

      })
      .catch((err) => {
        setLoading(false);
       toastMessage(err.response.data.message, "error");
      });
  };

  const handleEditPassword = async(e: any, user: any) => {
    e.preventDefault();

    const data = {
      old: inputValues.oldPassword,
      new: inputValues.newPassword,
      confirm: inputValues.confirmPassword,
    };
    if (inputValues.newPassword !== inputValues.confirmPassword) {
      setErr("Your password must match");

      setTimeout(() => {
        setErr("");
      }, 3000);

      return null;
    }

    PUT(`user/${user?.id}/changePassword`, data)
      .then((res) => {
        toastMessage("Password successfully changed", "success");
        setInputValues({
          ...inputValues,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        closeModal();
        window.location.reload();

      })
      .catch((err) => {
        toastMessage(err.response.data.message, "error");

      });
  };

  
  //search
  useEffect(() => {
    if (state.searchTerm !== "") {
      props
        .fetchUsers(
          `vendor/${vendor?.id}/users?search?searchTerm=${state.searchTerm}&limit=${limit}&page=${state.currentPage}`
        )

        .then((res: any) => {
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line
  }, [state.searchTerm]);

  const onSearch = (e: any) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, loading: true });
  };

  const next = () => {
    setLoading(true);
    let { currentPage } = state;
    currentPage = currentPage + 1;
    setState({ ...state, currentPage });
  };

  const prev = (e: any) => {
    setLoading(true);
    let { currentPage } = state;
    currentPage = currentPage - 1;
    setState({ ...state, currentPage });
  };
  return props.render({
    state,
    handleAddUser,
    setState,
    setInputValues,
    countryList,
    prev,
    next,
    handleOnChange,
    onSearch,
    handleChange,
    loading,
    inputValues,
    tableHeadings,
    items,
    limit,
    user,
    total,
    totalPages,
    vendors,
    getVendor,
    handleEditUser,
    handleEditPassword,
    handleOptions,
    actionBox,
    _show,
    closeModal,
    show,
    err,
  });
}

UsersProvider.defaultProps = {
  limit: 30,
  currentPage: 1,
  total: 0,
  totalPages: 1
};

const mapStateToProps = (state: any) => {
  return {
    items: state.user.users,
    currentPage: state.user.users.currentPage,
    limit: state.user.users.limit,
    total: state.user.users.total,
    totalPages: state.user.users.totalPages,
    vendor: state?.auth?.user?.vendor
  };
};

export default connect(mapStateToProps, { fetchUsers, addUser })(
  AuthProvider(withToastManager(UsersProvider))
);
