import React from "react";
import setAuthorisationToken from "../../../core/config/Interceptor";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../../store/actions/auth/auth";
import { useHistory } from "react-router";

const AuthProvider = (Component: any) => {
  const Authenticate = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();

    let user = localStorage.getItem("koboCareVendor_user");
    if (user) {
      user = JSON.parse(user);
    }

    const handleAuth = () => {
      const token = localStorage.getItem("koboCareVendor_tk");
      if (!token) {
        history.push("/");
      }
      setAuthorisationToken(token);
    };

    handleAuth();

    const handleLogout = () => {
      localStorage.removeItem("koboCareVendor_tk");
      localStorage.removeItem("koboCareVendor_user");
      dispatch(setCurrentUser({ isAuthenticated: false, user: {} }));
      history.push("/");
    };

    return <Component {...props} handleLogout={handleLogout} user={user} />;
  };

  Authenticate.defaultProps = {
    Component,
  };
  return Authenticate;
};

export default AuthProvider;
