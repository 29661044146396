import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import AuthProvider from "../../../shared/providers/AuthProvider/AuthProvider";
import { formatWord } from "../../../shared/utils/fx";
import { GET } from "../../../core/config/Api";
import { withToastManager } from "react-toast-notifications";
import {
  addProduct,
  editProduct,
  fetchProducts,
} from "../../../store/actions/product/product";
// import product from "../../../store/reducers/product/product";
import { useHistory } from 'react-router';
import { objInterface } from '../../../shared/utils/interfaces';

interface ProductsProviderProps {
  currentPage: any;
  limit: string;
  fetchProducts: (url: string) => Promise<any>;
  editProduct?: any;
  addProduct?: any;
  render: any;
  total: any;
  items: any;
  totalPages: any;
  toastManager?: any;
  vendor: { [key: string]: any }
};

function ProductsProvider(props: ProductsProviderProps) {
  const { items, currentPage, limit, total, totalPages, fetchProducts, vendor } = props;
  const [countryList, setCountryList] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    loading: false,
    totalPages: totalPages,
    limit: limit,
    currentPage: currentPage,
    searchTerm: "",
    search: false,
    list: "",
    searchDropdown: false,
    countryList: "",
  });

  const [inputValues, setInputValues] = useState({
    productId: "",
    unit: "",
    rate: "",
    country: "",
    currency: "",
    acquisitionRate: ""
  });
  const { push } = useHistory();

  const toastMessage = (message: string, type: string) => {
    props.toastManager.add(message, {
      appearance: type,
    });
  };
  
  useEffect(() => {
    document.title = "Kobo Care | Products";
    setLoading(true);

    fetchProducts(`product/vendor/${vendor.id}/all?limit=${limit}&page=${currentPage}`)
      .then((res: any) => {
        GET(`product/global?limit=1000`)
          .then(prod => {
            
            const filteredProd = prod.data.data.products?.filter((prod_: objInterface) => (prod_?.country === undefined)||(prod_?.country?.toLowerCase() === vendor?.country.toLowerCase()));
            setProducts(filteredProd);
          })
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);

        console.log({err});
      });
  }, [fetchProducts, currentPage, limit, vendor, vendor.id, vendor.country]);

  useEffect(() => {
    GET(`route/country`).then((res: any) => {
      setCountryList(res.data.data.countries);
    });
  }, []);

  const getCurrency = useCallback(() => {
    countryList?.map((currency: { [key: string]: any }) => {
      if (currency.country === inputValues.country) {
        setInputValues(inputValues => ({ ...inputValues, currency: currency.currency }));
      }
    });
  }, [countryList, inputValues.country]);

  const getProdDetails = useCallback(() => {
    products?.map((prod: { [key: string]: any }) => {
      if (prod._id === inputValues.productId) {
        setInputValues(inputValues => ({ ...inputValues, unit: prod.unit, country: prod.country}));
      }
    });
  }, [products, inputValues.productId]);

  useEffect(() => {
    if (inputValues.country) {
      //only call getCurrency when a country is being set to state
      getCurrency();
    }
  }, [inputValues.country, getCurrency]);


  useEffect(() => {
    if (inputValues.productId) {
      //only call getCurrency when a country is being set to state
      getProdDetails();
    }
  }, [inputValues.productId, getProdDetails]);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleAddProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const data = {
      productId: inputValues.productId,
      unit: inputValues.unit,
      acquisitionRate: parseInt(inputValues.rate, 10),
      country: formatWord(inputValues.country),
      currency: inputValues.currency,
      vendorId: props.vendor.id,
      vendorName: props.vendor.name,
    };

    props.addProduct(`product`, data).then((res: any) => {
      toastMessage("Product successfully created", "success");
      setInputValues({
        ...inputValues,
        productId: "",
        unit: "",
        rate: "",
        country: "",
        currency: "",
        acquisitionRate: ""
      });
      push('/products');
    }).catch((err: any) => {
      toastMessage(err.response.data.message, "error");

    });
  };

  const tableHeadings: Array<{ key: string, title: string, formatter?: any }> = [
    {
      key: "name",
      title: "Product",
    },
    {
      key: "unit",
      title: "Unit of measure",
    },

    {
      key: "unitPrice",
      title: "Unit Price",
      formatter: (data: any) => <div className="tableText text-left">
        {data && data.country ? data.currency : ""} {data?.rate}

      </div>
    },
    {
      key: "country",
      title: "Product Country",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText text-left">
              {" "}
              {data && data.country ? formatWord(data.country) : "N/A"}
            </div>

          </>
        );
      },
    },

    {
      key: "productStatus",
      title: "Product Status",
      formatter: (data: any) => {
        return (
          <>
            <div className="tableText status">
              <span
                className={
                  data?.activated
                    ? "statusSuccess"

                    : "statusFail"
                }
              />
              &nbsp; &nbsp;
              <span className={data?.activated
                ? 'green' : 'red'}>
                {data?.activated ? 'Active' : "Inactive"}
              </span>
            </div>

          </>
        );
      },
    },


  ];

  //search
  useEffect(() => {
    if (state.searchTerm !== "") {
      props
        .fetchProducts(
          `product/global/search?searchTerm=${state.searchTerm}&limit=${limit}&page=${state.currentPage}`
        )

        .then((res: any) => {
          setState({ ...state, loading: false });
        })
        .catch((err: any) => {
          setState({ ...state, loading: false });
        });
    }
    // eslint-disable-next-line
  }, [state.searchTerm]);

  const onSearch = (e: any) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value, search: true, loading: true });
  };

  // pagination
  useEffect(() => {
    if (state.currentPage > 1) {
      props
        .fetchProducts(`product/vendor/${props.vendor.id}/all?limit=${limit}&page=${state.currentPage}`)
        .then((res: any) => {
          setState({ ...state, loading: false });
        })
        .catch((err: any) => {
          // handleError(err);
          setState({ ...state, loading: false });
        });
    }
    // eslint-disable-next-line
  }, [state.currentPage]);

  const next = () => {
   const { currentPage } = state;
    setState({ ...state, currentPage: currentPage + 1 });
  };

  const prev = (e: any) => {
   const { currentPage } = state;
    setState({ ...state, currentPage: currentPage - 1 });
  };

  return props.render({
    state,
    handleAddProduct,
    setState,
    setInputValues,
    countryList,
    prev,
    next,
    onSearch,
    handleChange,
    loading,
    inputValues,
    tableHeadings,
    items,
    limit,
    total,
    totalPages,
    products
  });
}

ProductsProvider.defaultProps = {
  limit: 30,
  currentPage: 1,
};

const mapStateToProps = (state: any) => {
  return {
    items: state.product.products,
    currentPage: state.product.products.currentPage,
    limit: state.product.products.limit,
    total: state.product.products.total,
    totalPages: state.product.products.totalPages,
    vendor: state.auth.user.vendor
  };
};

export default connect(mapStateToProps, {
  addProduct,
  fetchProducts,
  editProduct,
})(AuthProvider(withToastManager(ProductsProvider)));
