import * as React from "react";
import "./Button.scss";

export interface IProps {
  className?: string;
  title?: string;
  loading?: boolean;
  type: "submit" | "button" | "reset";
  icon?: string;
  onClick?: (e:any) => void;
  disabled?: boolean
}

function Button(props: IProps) {
  return (
    <button
      type={props.type}
      className={props.className}
      onClick={(e) => props.onClick && props.onClick(e)}
      disabled={props.disabled}
    >
      {props.icon && <img src={props.icon} alt="icon-button" />}
      {props.loading ? "...loading" : <p>{props.title}</p>}
    </button>
  );
}

export default Button;
