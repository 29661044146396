import {ADD_CONTRACT, REJECT_CONTRACT, SET_CONTRACTS,} from "../../types/types";

type InitialState = {
    contracts: {
        contracts: any[];
    };
};

const initialState: InitialState = {
    contracts: {
        contracts: [],
    },
};


type Action = {
    type: "SET_CONTRACTS" | "ADD_CONTRACT" | "REJECT_CONTRACT";
    contract?: any;
    reject?: any;
    contracts?: any[];
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_CONTRACTS:
            return {
                ...state,
                contracts: action.contracts,
            };

        case ADD_CONTRACT:
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    contracts: state.contracts.contracts.filter(
                        (contract) => contract.contracts._id !== action.contract._id
                    ),
                },
            };

        case REJECT_CONTRACT:
            return {
                ...state,
                contracts: {
                    ...state.contracts,
                    contracts: state.contracts.contracts.map((reject) => {
                        if (reject.contracts._id !== action.reject._id) {
                            return reject;
                        }
                        reject.contracts.rejected = true;

                        return {
                            ...reject,
                            ...action.reject,
                        };
                    }),
                },
            };

        default:
            return state;
    }
};
