import * as React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div className={"footer"}>
      <ul>
        <li>Privacy</li>
        <li>Terms</li>
        <li>Help</li>
      </ul>
      <p>&copy; Copyright Kobo360, {new Date().getFullYear()} </p>
    </div>
  );
}

export default Footer;
