import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/store";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { setCurrentUser } from "./store/actions/auth/auth";

if(process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: "https://b8d0f79dbaa64b81a3d1d5e8b99a4b69@sentry.io/1476489"});

}
if(localStorage.getItem('koboCareVendor_user')) {
  const user = JSON.parse(localStorage?.getItem('koboCareVendor_user') ?? '{}');
  store.dispatch(setCurrentUser(user))
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
