import {
    MONTHLY_SALES,
    TOP_SELLING_PRODUCT,
    TOP_SELLING_VENDOR,
    TOP_STATIONS,
    TOTAL_AMOUNT_SOLD,
} from "../../types/types";

type InitialState = {
    analytics?: any[];
    vendors?: any[];
    amountSold?: string;
    sales?: any[];
    stations?: any[];
    amount?: string;
};
const initialState: InitialState = {
    analytics: [],
    vendors: [],
    amountSold: '',
    sales: [],
    stations: [],
    amount: '',
};
type Action = {
    type?:
        | "TOP_SELLING_PRODUCT"
        | "TOP_SELLING_VENDOR"
        | "TOTAL_AMOUNT_SOLD"
        | "MONTHLY_SALES"
        | "TOP_STATIONS";
    analytics?: any[];
    vendors?: any[];
    amountSold?: string;
    sales?: any[];
    stations?: any[];
    amount?: string;
};
export default (state = initialState, action: Action) => {
    switch (action.type) {
        case TOP_SELLING_PRODUCT:
            return {
                ...state,
                analytics: action.analytics,
            };

        case TOP_SELLING_VENDOR:
            return {
                ...state,
                vendors: action.vendors,
            };
        case TOTAL_AMOUNT_SOLD:
            return {
                ...state,
                amountSold: action.amount,
            };
        case MONTHLY_SALES:
            return {
                ...state,
                sales: action.sales,
            };
        case TOP_STATIONS:
            return {
                ...state,
                stations: action.stations,
            };
        default:
            return state;
    }
};
