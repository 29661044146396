import React from "react";
import "../Vendor.scss";
import CardContainer from "../../../shared/components/CardContainer/CardContainer";
import Card from "../../../shared/components/Card/Card";
import Table from "../../../shared/components/Table/Table";
import Button from "../../../shared/components/Button/Button";
// import Modal from "../../../shared/components/Modal/Modal";
// import Spinner from "../../../shared/components/Spinner/Spinner";

function VendorStation(props: any) {
  const {
    items,
    state,
    totalPages,
    tableHeadings,
    // closeManage,
    // showCode,
    next,
    // closeModal,
    // statusChange,
    prev,
    total,
    currentPage,
    vendor,
    // onSearch
  } = props;
  return (
    <>
      <CardContainer className={"justify-space-between align-end"}>
        <Card className={"green-card"} title={`${total ?? 0}`} description={"stations"} />
        <Button
          type={"button"}
          onClick={() =>
            props.history.push(`/stations/${vendor?.id}/add-station`)
          }
          icon={require("../../../assets/images/add-white.svg")}
          className={"button--green"}
          title={"Add Station"}
        />
      </CardContainer>
      <Table
        headings={tableHeadings || []}
        showHelmet={true}
        paginated={totalPages > 1}
        loading={state.loading}
        exportList={false}
        onNext={next}
        onPrev={prev}
        // onSearch={onSearch}
        // searchTerm={state.searchTerm}
        currentPage={currentPage || 1}
        totalPages={totalPages || 1}
        data={items}
        title={`Stations - ${vendor?.name} `}
      />

      {/* {state.manage ? (
        <Modal onNullClick={(e) => e.stopPropagation()} onClick={closeManage}>
          {state.loading_ ? (
            <Spinner />
          ) : (
            <div className="station__details">
              <div>
                <strong>Station Name</strong>
                <p>{state.station ? state.station.name : ""}</p>
              </div>
              <div>
                <strong>Location</strong>
                <p>{state.station ? state.station.location : ""}</p>
              </div>
              <div>
                <strong>Manager</strong>
                <p>
                  {state.station
                    ? `${
                        state.station.manager !== null
                          ? state.station.manager.first_name
                          : "not"
                      } ${" "}
              ${
                state.station.manager !== null
                  ? state.station.manager.last_name
                  : "available"
              }`
                    : ""}
                </p>
              </div>
              <div>
                <strong>Station passcode</strong>
                <p className="d-flex align-center">
                  <span className="mr-1">
                    {!state.code ? (
                      <>***************</>
                    ) : (
                      <>{state.station.pass_code}</>
                    )}
                  </span>
                  <img
                    onClick={showCode}
                    className="pointer"
                    src={require("../../../assets/images/view.svg")}
                    alt=""
                  />
                </p>
              </div>

            </div>
          )}
        </Modal>
      ) : (
        ""
      )} */}

      {/* {state.show ? (
        <Modal onNullClick={(e) => e.stopPropagation()} onClick={closeModal}>
          <div className="prompt p-2 text-center">
            <strong className="mb-1">Warning !</strong>
            <p className="mb-1">
              Are you sure you want to activate(deactivate) this product?
            </p>
            <div className="d-flex justify-space-around">
              <Button
                loading={state.loading_}
                onClick={(e) => statusChange(e, state.product, state.station)}
                type={"button"}
                className={"button--sm button--green"}
                title={"yes"}
              />
              <Button
                type={"button"}
                className={"button--sm button--red"}
                onClick={closeModal}
                title={"no"}
              />
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )} */}
    </>
  );
}

export default VendorStation;
