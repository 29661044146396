import { ADD_STATION, SET_STATIONS } from "../../types/types";
import { POST, GET } from "../../../core/config/Api";
import { SET_CURRENT_USER } from "../../types/types";

export const setCurrentUser = (user: any) => {
  return {
    type: SET_CURRENT_USER,
    user,
  };
};
export const setStation = (stations: any) => {
  return {
    type: SET_STATIONS,
    stations,
  };
};

export const saveStation = (station: any) => {
  return {
    type: ADD_STATION,
    station,
  };
};
export const addStation = (endpoint: string, data: any) => (dispatch: any) => {
  return POST(endpoint, data).then((res: any) => {
    dispatch(saveStation(res.data.data.station));
  });
};

export const fetchStations = (endpoint: string) => (dispatch: any) => {
  return GET(endpoint).then((res: any) => {
    dispatch(setStation(res.data.data));
  });
};
