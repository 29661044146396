import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Transactions from "./pages/Transactions/Transactions";
// import CardList from "./pages/Card/CardList";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import AuthProvider from "./shared/providers/AuthProvider/AuthProvider";
import { ToastProvider } from "react-toast-notifications";
// import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
// import Partners from "./pages/Partners/Partners";
// import AddDriver from "./pages/Drivers/AddDriver/AddDriver";
// import AddTruck from "./pages/Truck/AddTruck/AddTruck";
// import TruckList from "./pages/Truck/TruckList/TruckList";
// import DriversList from "./pages/Drivers/DriversList/DriversList";
// import OnBoardedPartners from "./pages/Partners/OnboardedPartners/OnboardedPartners";
// import SubscribedPartners from "./pages/Partners/SubscribedPartners/SubscribedPartners";
// import AddPartner from "./pages/Partners/AddPartner/AddPartner";
import Vendor from "./pages/Vendor/Vendor";
// import VouchersList from "./pages/Vouchers/VoucherList/VouchersList";
// import AddVoucher from "./pages/Vouchers/AddVoucher/AddVoucher";
import AddProduct from "./pages/Products/AddProduct/AddProduct";
import ProductList from "./pages/Products/ProductList/ProductList";
// import ContractList from "./pages/Contract/ContractList/ContractList";
// import Incentive from "./pages/Incentives/Incentive";
// import ProductApproval from "./pages/ProductApprovals/ProductApproval";
// import ProductReconciliationList from "./pages/ProductReconciliation/ProductReconciliationList/ProductReconciliationList";
// import BankList from "./pages/Bank/BankList/BankList";
import UsersList from "./pages/Users/UsersList/UsersList";
import AddUser from "./pages/Users/AddUser/AddUser";
// import PriceApproval from "./pages/PriceApprovals/PriceApproval";
// import VendorReconciliation from "./pages/VendorReconciliation/VendorReconciliation";
// import TripTransaction from "./pages/TripTransaction/TripTransaction";
// import Setting from "./pages/Setting/Setting";

import "./styles/App.scss";
import Profile from './pages/Profile/Profile';


const App: React.FC = () => {
  return (
    <ToastProvider autoDismiss>
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          {/* <Route path="/login" exact component={Login} /> */}
          <Route path='/login'>
            <Redirect to='/' />
          </Route>
          <Route path="/forgot-password" exact component={ResetPassword} />
          <Route path="/reset-password" exact component={ResetPassword} />
          {/* <Route path="/dashboard" exact component={Dashboard} /> */}
          <Route path="/sales" exact component={Transactions} />
          <Route path="/stations" exact component={Vendor} />
          {/* <Route path="/vendor/:id/add-user" exact component={Vendor} /> */}
          <Route path="/stations/:id/add-station" exact component={Vendor} />
          <Route path="/stations/:id" exact component={Vendor} />

          {/* <Route path="/partners" exact component={AuthProvider(Partners)} />

          <Route
            path="/onboarded-partners"
            exact
            component={AuthProvider(OnBoardedPartners)}
          />
          <Route
            path="/subscribed-partners"
            exact
            component={AuthProvider(SubscribedPartners)}
          />
          <Route
            path="/add-partner"
            exact
            component={AuthProvider(AddPartner)}
          /> */}

          {/* <Route path="/drivers" exact component={AuthProvider(DriversList)} />
          <Route path="/add-driver" exact component={AuthProvider(AddDriver)} />
          <Route path="/add-truck" exact component={AuthProvider(AddTruck)} /> */}
          {/* <Route
            path="/truck-management"
            exact
            component={AuthProvider(TruckList)}
          /> */}
          {/* <Route path="/vendor/:id/add-product" exact component={Vendor} />
          <Route path="/vendors/add-vendor" exact component={Vendor} />
          <Route path="/vendor/:id" exact component={Vendor} />
          <Route path="/vendor/:id/stations" exact component={Vendor} />
          <Route path="/vendor/:id/station/:id" exact component={Vendor} />
          <Route path="/vendor/:id/users" exact component={Vendor} />
          <Route path="/vendor/:id/products" exact component={Vendor} />
          <Route path="/card/:id/detail" exact component={CardList} /> */}

          {/* <Route path="/card-management" exact component={CardList} />
          <Route path="/vouchers" exact component={VouchersList} />
          <Route path="/create-voucher" exact component={AddVoucher} /> */}
          <Route path="/products" exact component={AuthProvider(ProductList)} />
          <Route
            path="/add-product"
            exact
            component={AuthProvider(AddProduct)}
          />
          {/* <Route
            path="/contracts"
            exact
            component={AuthProvider(ContractList)}
          /> */}
          {/* <Route path="/cash-incentives" exact component={Incentive} />
          <Route path="/redeemed-incentives" exact component={Incentive} />
          <Route path="/create-cash-incentive" exact component={Incentive} />
          <Route path="/product-approvals" exact component={ProductApproval} /> */}

          {/* <Route path="/bank" exact component={BankList} /> */}
          <Route path="/user-management" exact component={UsersList} />
          <Route path="/add-user" exact component={AddUser} />

          {/* <Route
            path="/product-reconciliation"
            exact
            component={ProductReconciliationList}
          /> */}

          {/* <Route path="/price-approvals" exact component={PriceApproval} />
          <Route
            path="/vendor-reconciliations"
            exact
            component={VendorReconciliation}
          />
          <Route
            path="/vendor-reconciliation/:id"
            exact
            component={VendorReconciliation}
          />
          <Route path="/trip-transactions" exact component={TripTransaction} /> */}
          <Route path="/profile" exact component={Profile} />
        </Switch>
      </Router>
    </ToastProvider>
  );
};
export default App;
