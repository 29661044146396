import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./../Vendor.scss";
import Step from "../../../shared/components/Step/Step";
import Form from "../../../shared/components/Form/Form";
import { objInterface } from '../../../shared/utils/interfaces';
import { GET } from '../../../core/config/Api';
import { formatWord, sortArray } from "../../../shared/utils/fx";
import Popup from '../../../shared/components/Card/Popup';

function AddStation(props: any) {
  const { state, addStation, onChange, history } = props;
  const [countries, setCountries] = useState<Array<objInterface>>([]);
  const [states, setStates] = useState<Array<objInterface>>([]);
  const [autoComplete, setAutoComplete] = useState<Array<objInterface>>([]);
  const [val, setVal] = useState('');


  const getCountry = useCallback(async () => {
    try {
      const res = await GET('route/country');
      setCountries(res.data.data.countries)
    } catch (error) {

    }
  }, [])
  useEffect(() => {
    getCountry()
  }, [getCountry])

  const getStates = useCallback(async () => {
    const code = countries.find((country:objInterface) => country.country.toLowerCase() === state.country.toLowerCase())?.countryCode;
    try {
      const res = await GET(`route/country/${code}/states`);
      setStates(res.data.data.states)
    } catch (error) {

    }
  }, [countries, state.country])
  useEffect(() => {
    if(state.country) {
      getStates()
    }
  }, [getStates, state.country])

  const handleAutoComplete = (e: { target: HTMLInputElement }) => {
    setVal(e.target.value)

  }

  const getAutoComplete = useCallback(async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_GPS_ENDPOINT}/v2/location/autocomplete?input=${val}`);
      setAutoComplete(res.data.data.autocomplete);
    } catch (error) {

    }
  }, [val])

  useEffect(() => {
    if(val) {
      getAutoComplete()
    }
  }, [getAutoComplete, val])

  const selectLocation = async (val_: objInterface) => {
    setVal('');
    state.location = val_.description
    try {
      const res = await axios.get(`${process.env.REACT_APP_GPS_ENDPOINT}/v2/location/place?placeId=${val_.placeId}&source=google`);
      if(res) {
        const coord = res.data.data.place.geometry.location;
        state.lat = coord.lat;
        state.lng = coord.lng;
      }
    } catch (error) {
      setVal(val_.description);
      
    }
  }


  return (
    <>
      <div className="right" />
      <div>
        <Step steps={[true]} />


        <Form
          title={"Add Station"}
          onCancel={() => history.push("/stations")}
          footer={
            <Form.Footer className={"justify-center align-center"}>
              <Form.Save
                loading={state.loading_}
                title={"Save"}
                onSave={addStation}
              />
            </Form.Footer>
          }

        >
          <Form.Input
            type={"text"}
            value={state.name}
            label={"Name"}
            required={true}
            onChange={onChange}
            name={"name"}
          />

          <div className="d-flex align-center">
            <Form.Select
              name="country"
              value={state.country}
              label={"Country"}
              onChange={onChange}
              className="mr-1"

            >
              <option></option>

              {countries
                ? sortArray(countries, 'country').map((country: any) => (
                  <option
                    key={country.countryCode}
                    value={country.country}
                  >
                    {formatWord(country.country)}
                  </option>
                ))
                : ""}
            </Form.Select>
            
            <Form.Select
              name="state"
              value={state.state}
              label={"State"}
              onChange={onChange}

            >
              <option></option>

              {states
                ? sortArray(states, 'state').map((state: any) => (
                  <option
                    key={state._id}
                    value={state.state}
                  >
                    {formatWord(state.state)}
                  </option>
                ))
                : ""}
            </Form.Select>

          </div>
          <div className="d-flex align-center">

          <Form.Input
            type={"text"}
            value={state.location || val}
            label={"Location"}
            onChange={handleAutoComplete}
            required={true}
            name={"location"}
            className="mr-1"
          />
          {val && 
            <Popup 
              autocomplete= {autoComplete} 
              selectLocation={selectLocation} 
            />
          }
          <Form.Input
            type={"text"}
            value={state.land_mark}
            label={"Nearest Landmark (Optional)"}
            required={true}
            onChange={onChange}
            name={"land_mark"}
          />
          </div>

          <Form.Select
            value={state.manager_id}
            label={"Assign Manager"}
            onChange={onChange}
            name={"manager_id"}
            required={true}
          >
            <option value="-1">Select a manager</option>
            {state.users.filter((user: objInterface) => user.role === 'manager').length
              ? state.users.filter((user: objInterface) => user.role === 'manager').map((user: any) => {
                return (
                  <option key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}
                  </option>
                );
              })
              : ""}
          </Form.Select>
        </Form>

      </div>
      <div className="left" />
    </>
  );
}

export default AddStation;
