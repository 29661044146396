import * as React from "react";
import "../Products.scss";
import MainLayout from "../../../shared/components/MainLayout/MainLayout";
import ContentLayout from "../../../shared/components/ContentLayout/ContentLayout";
import Form from "../../../shared/components/Form/Form";
import Step from "../../../shared/components/Step/Step";
import "../../../shared/components/Dropdown/Dropdown.scss";
import ProductsProvider from "../ProductsProvider/ProductsProvider";
import { formatWord, sortArray } from "../../../shared/utils/fx";

interface ProductProps {
  state: any;
  setState: (e: any) => void;
  setInputValues: (e: any) => void;
  inputValues: any;
  handleSubmit: any;
  handleAddProduct: (e: any) => void;
  handleChange: (e: any) => void;
  countryList: any;
  history: {
    push: (params: string) => void;
  };
};

function AddProduct(props: ProductProps) {
  return (
    <>
      <MainLayout>
        <ProductsProvider
          render={({
            inputValues,
            handleAddProduct,
            handleChange,
            state,
            setState,
            setInputValues,
            countryList,
            products
          }: any) => {
            return (
              <ContentLayout title={"Add Product"}>
                <div className="right" />
                <div>
                  <Step steps={[true]} />
                  <Form
                    onCancel={() => props.history.push("/products")}
                    title={"Add Product"}
                    onSubmit={(e) => handleAddProduct(e)}
                    footer={
                      <Form.Footer className={"justify-center"}>
                        <Form.Save
                          title={"Add Product"}
                          onSave={handleAddProduct}
                        />
                      </Form.Footer>
                    }
                  >

                    <Form.Select
                      name="productId"
                      value={inputValues.productId}
                      label={"Product"}
                      onChange={handleChange}
                    >
                      <option></option>

                      {sortArray(products, 'name')?.map((prod: any) => (
                        <option
                          key={prod._id}
                          value={prod._id}
                        >
                          {formatWord(prod.name)}
                        </option>
                      ))
                      }
                    </Form.Select>
                    <div className="d-flex align-center">
                      <Form.Input
                        type={"text"}
                        name="rate"
                        className="mr-1"
                        value={inputValues.rate}
                        label={"Acquisition rate"}
                        onChange={handleChange}
                      />
                      <Form.Input
                        type={"text"}
                        name="unit"
                        value={inputValues.unit}
                        label={"Unit of measure"}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className="d-flex align-center">
                      {/* <Form.Input
                        type={"text"}
                        name="cashDiscount"
                        value={inputValues.cashDiscount}
                        label={"Cash Discount"}
                        className="mr-1"
                        onChange={handleChange}
                      /> */}
                      <Form.Input
                      type={"text"}
                      name="currency"
                      value={inputValues.currency}
                      label={"Currency"}
                      // onChange={handleChange}
                      disabled={true}
                      className="mr-1"

                    />
                      <Form.Select
                      name="country"
                      value={inputValues.country}
                      label={"Country"}
                      onChange={handleChange}
                      disabled
                    >
                      <option></option>

                      {countryList
                        ? sortArray(countryList, 'country').map((country: any) => (
                          <option
                            key={country.countryCode}
                            value={country.country}
                          >
                            {formatWord(country.country)}
                          </option>
                        ))
                        : ""}
                    </Form.Select>
                    </div>
                    

                    
                  </Form>
                </div>
              </ContentLayout>
            );
          }}
        />
      </MainLayout>
    </>
  );
}

export default AddProduct;
