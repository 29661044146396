import React from 'react'
import './ImageUploader.scss'

interface ImageUploaderProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  preview?: string
  vendorImage?: string
  title?: string
  profile?: boolean
  progress?: number
}

function ImageUploader(props: ImageUploaderProps) {
  return (
    <div className="imageUploader">
      {!props.preview && <small>{props.title}</small>}

      {props.profile ? (
        <div className="imageUploader__circle">
          {props.vendorImage ? (
            <img
              src={props.preview ? props.preview : props.vendorImage}
              alt="profile uploader"
            />
          ) : props.progress ? (
            <div
              style={{
                width: `${props.progress}`,
                height: 5,
                backgroundColor: '#14213D',
              }}
            />
          ) : (
            <img
              src={
                props.preview
                  ? props.preview
                  : require('../../../assets/images/XMLID_1325_.svg')
              }
              alt="profile uploader"
            />
          )}
          <div className="imageUploader__greenButton">
            <img
              src={require('../../../assets/images/add-white.svg')}
              alt=" green button"
            />
          </div>
        </div>
      ) : (
        props.preview &&
        (props.progress ? (
          <div
            style={{
              width: `${props.progress}`,
              height: 5,
              backgroundColor: '#14213D',
            }}
          />
        ) : (
          <img src={props.preview} alt="profile uploader" />
        ))
      )}

      <input
        type="file"
        className="imageUploader__fileInput"
        onChange={props.onChange}
      />
    </div>
  )
}

export default ImageUploader
