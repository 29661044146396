import { useState } from "react";
import { connect } from "react-redux";
import { auth } from "../../../store/actions/auth/auth";
import {useHistory} from "react-router";

interface LoginProviderProps {
  render: any;
  auth: any;
};

function LoginProvider(props: LoginProviderProps) {
  const {auth } = props;
  const [email, setEmail] = useState("");
  const [secret, setSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setrErrMsg] = useState("");
  const history = useHistory();

  const handleSubmit = (e: any) => {
    setLoading(true);
    e.preventDefault();

    const data = { email, secret, user_type: "vendor" };
     auth("user/auth", data)
      .then((res: {[key: string]: any}) => {
        
        setError(false);
        setTimeout(() => {
          setLoading(false);
          history.push("/sales");
        }, 1000)
      })
      .catch((err: any) => {
        setLoading(false);
        setError(true);
        setrErrMsg(
          err.response
            ? err.response.data.message
            : "Check your Internet connection"
        );

        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };


  return props.render({
    email,
    secret,
    loading,
    errMsg,
    error,
    handleSubmit,
    setEmail,
    setSecret
  });
}

export default connect(null, { auth })(LoginProvider);
