import {
  SET_CURRENT_PAGE,
  SET_LIMIT,
  SET_TRIP_TRANSACTIONS,
} from "../../types/types";

type InitialState = {
  tripTransactions: {
    limit: number;
    currentPage: number;
  };
};

const initialState: InitialState = {
  tripTransactions: {
    limit: 30,
    currentPage: 1,
  },
};
type Action = {
  type: "SET_TRIP_TRANSACTIONS" | "SET_LIMIT" | "SET_CURRENT_PAGE";
  tripTransactions?: any[];
  limit?: any;
  currentPage?: any;
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_TRIP_TRANSACTIONS:
      return {
        ...state,
        tripTransactions: action.tripTransactions,
      };

    case SET_LIMIT:
      return {
        ...state,
        tripTransactions: {
          ...state.tripTransactions,
          limit: action.limit,
        },
      };

    case SET_CURRENT_PAGE:
      console.log("action", action);
      return {
        ...state,
        tripTransactions: {
          ...state.tripTransactions,
          currentPage: action.currentPage,
        },
      };

    default:
      return state;
  }
};
