import {ACCEPT_WALLET_REQUEST, GET_WALLET_REQUEST} from "../../types/types";

type InitialState = {
    wallets: {
        wallets: any[];
    };
};

const initialState: InitialState = {
    wallets: {
        wallets: [],
    },
};

type Action = {
    type: "GET_WALLET_REQUEST" | "ACCEPT_WALLET_REQUEST";
    wallets: any[];
    wallet: any;
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case GET_WALLET_REQUEST:
            return {
                ...state,
                wallets: action.wallets,
            };
        case ACCEPT_WALLET_REQUEST:
            return {
                ...state,
                wallets: {
                    ...state.wallets,
                    wallets: state.wallets.wallets.filter(
                        (accept) => accept._id !== action.wallet.wallet._id
                    ),
                },
            };
        default:
            return state;
    }
};
