import React, { ReactNode } from "react";
import "./TopContainer.scss";

type TopContainerProps = {
  className?: string;
  children: ReactNode;
};
const TopContainer = (props: TopContainerProps) => {
  return (
    <div className={`topContainer ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

export default TopContainer;
