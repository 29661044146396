import { ADD_USER, SET_USERS } from "../../types/types";

type InitialState = {
  users: {
    limit: number;
    currentPage: number;
    users: any[];
  };
};
const initialState: InitialState = {
  users: {
    limit: 30,
    currentPage: 1,
    users: [],
  },
};
type Action = {
  type: "SET_USERS" | "ADD_USER";
  user: any;
  users: any[];
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          users: [
            {
              ...action.user,
            },

            ...state.users.users,
          ],
        },
      };

    case SET_USERS:
      return {
        ...state,
        users: action.users,
      };

    default:
      return state;
  }
};
