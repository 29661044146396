import {SET_CURRENT_USER} from "../../types/types";

type InitialState = {
    isAuthenticated: boolean;
    user: any;
};

const initialState: InitialState = {
    user: {},
    isAuthenticated: false,
};

type Action = {
    type: "SET_CURRENT_USER";
    user?: any;
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.user,
            };
        default:
            return state;
    }
};
