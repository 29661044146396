import React, { ReactNode } from "react";
import "./NavBar.scss";

interface TitleProps {
  title?: string;
  className?: string;
};
interface Props {
  children: ReactNode;
  className?: string;
};



const NavBar = (props: Props) => {
  return <div className={`navBar ${props.className || ''}`}>{props.children}</div>;
};

const Title = (props: TitleProps) => {
  return <h3 className={`navBar__title ${props.className || ''}`}>{props.title}</h3>;
};

const Nav = (props: Props) => {
  return (
    <div className={`navBar__nav ${props.className || ""}`}>
      {props.children}
    </div>
  );
};
const NavItem = (props: Props) => {
  return (
    <div className={`navBar__navItem ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

const DropDown = (props: Props) => {
  return (
    <div className={`navBar__dropDown ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

const DropDownMenu = (props: Props) => {
  return (
    <ul className={`navBar__dropDownMenu ${props.className || ""}`}>
      {props.children}
    </ul>
  );
};

NavBar.Title = Title;
NavBar.Nav = Nav;
NavBar.NavItem = NavItem;
NavBar.DropDown = DropDown;
NavBar.DropDownMenu = DropDownMenu;

export default NavBar;
