import * as React from "react";

import "../Users.scss";
import MainLayout from "../../../shared/components/MainLayout/MainLayout";
import ContentLayout from "../../../shared/components/ContentLayout/ContentLayout";
import Card from "../../../shared/components/Card/Card";
import Table from "../../../shared/components/Table/Table";
import TopContainer from "../../../shared/components/TopContainer/TopContainer";
import Button from "../../../shared/components/Button/Button";
import { RouteComponentProps } from "react-router-dom";
import UsersProvider from "../UsersProvider/UsersProvider";
import CardContainer from "../../../shared/components/CardContainer/CardContainer";
import Modal from "../../../shared/components/Modal/Modal";
import UserModal from "../UserModal/UserModal";

interface Props extends RouteComponentProps {
}

const UsersList = (props: Props) => {
  return (
    <MainLayout>
      <UsersProvider
        render={({
          state,
          tableHeadings,
          loading,
          items,
          closeModal,
          total,
          prev,
          next,
          totalPages,
          show,
          user,
          _show,
        }: any) => {
          return (
            <ContentLayout title={"User Management"}>
              <TopContainer>
                <CardContainer>
                  <Card
                    className={"green-card"}
                    title={total}
                    description={"Users"}
                  />
                </CardContainer>
                <Button
                  type={"button"}
                  icon={require("../../../assets/Icons/add.svg")}
                  title="ADD USER"
                  className="button--green"
                  onClick={()=> props.history.push(`/add-user`)}
                />
              </TopContainer>
              {show && (
                <Modal
                  onNullClick={(e) => e.stopPropagation()}
                  onClick={closeModal}
                >
                  <UserModal
                    title="Edit User"
                    onClick={closeModal}
                    user={user}
                    type="user"
                  />
                </Modal>
              )}
              {_show && (
                <Modal
                  onNullClick={(e) => e.stopPropagation()}
                  onClick={closeModal}
                >
                  <UserModal
                    title="Edit password"
                    onClick={closeModal}
                    user={user}
                    type="password"
                  />
                </Modal>
              )}
              <Table
                showHelmet={true}
                loading={loading}
                headings={tableHeadings}
                paginated={totalPages > 1}
                exportList={true}
                openBox={true}
                // options={handleOptions}
                onNext={next}
                onPrev={prev}
                currentPage={state.currentPage}
                totalPages={totalPages}
                data={
                   items?.users?.length ? items.users : []
                }
                title={"Users List"}
              />
            </ContentLayout>
          );
        }}
      />
    </MainLayout>
  );
};
export default UsersList;
