import {ADD_DRIVER, SET_DRIVERS} from "../../types/types";

type InitialState = {
    drivers: {
        drivers: any[];
    };
};

const initialState: InitialState = {
    drivers: {
        drivers: [],
    },
};

type Action = {
    type: "SET_DRIVERS" | "ADD_DRIVER";
    driver?: any;
    drivers?: any[];
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_DRIVERS:
            return {
                ...state,
                drivers: action.drivers,
            };
        case ADD_DRIVER:
            return {
                ...state,
                drivers: {
                    ...state.drivers,
                    drivers: [{...action.driver}, ...state.drivers.drivers],
                },
            };
        default:
            return state;
    }
};
