import { combineReducers } from "redux";
import auth from "../auth/auth";
import station from "../station/station";
import product from "../product/product";
import user from "../user/user";
import approval from "../approval/approval";
import contract from "../contract/contract";
import vendor from "../vendor/vendor";
import card from "../card/card";
import analytics from "../analytics/analytics";
import bank from "../bank/bank";
import truck from "../truck/truck";
import partner from "../partner/partner";
import incentive from "../incentive/incentive";
import vendorReconciliation from "../vendorReconciliation/vendorReconciliation";
import productReconciliation from "../productReconciliation/productReconciliation";
import transaction from "../transaction/transaction";
import voucher from "../voucher/voucher";
import driver from "../driver/driver";
import walletRequests from "../walletRequest/walletRequest";
import subscribedPartners from "../subscribedPartners/subscribedPartners";
import tripTransaction from "../tripTransaction/tripTransaction";

export default combineReducers({
  auth,
  station,
  product,
  user,
  approval,
  contract,
  vendor,
  card,
  transaction,
  tripTransaction,
  analytics,
  walletRequests,
  truck,
  partner,
  bank,
  driver,
  incentive,
  voucher,
  vendorReconciliation,
  productReconciliation,
  subscribedPartners,
});
