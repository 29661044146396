import React, { ReactNode } from "react";
import "./SideMenu.scss";
import { NavLink } from "react-router-dom";

interface SieMenuProps {
  children: ReactNode;
  className?: string;
};

interface SideMenuHeaderProps {
  title: string;
  className?: string;
};
interface SideMenuSubHeaderProps {
  title: string;
  className?: string;
};
interface SideMenuListProps {
  children: ReactNode;
  className?: string;
};

interface SideMenuItemProps {
  title: string;
  link?: string;
  icon?: string;
  onClick?: () => void;
};

type onClick = () => void;

const SideMenu = (props: SieMenuProps) => {
  return (
    <aside className={`sideMenu ${props.className || ""}`}>
      {props.children}
    </aside>
  );
};

const Header = (props: SideMenuHeaderProps) => {
  return (
    <div className="sideMenu__header">
      <img
        src={require("../../../assets/images/kobocare-white.png")}
        alt="logo"
        className={"sideMenu__logo"}
      />
      <p className="sideMenu__title">{props.title}</p>
    </div>
  );
};
const SubHeader = (props: SideMenuSubHeaderProps) => {
  return (
    <p className={`sideMenu__subHeader ${props.className || ""}`}>
      {props.title}
    </p>
  );
};

const List = (props: SideMenuListProps) => {
  return (
    <ul className={`sideMenu__list ${props.className || ""}`}>
      {props.children}
    </ul>
  );
};
const Item = (props: SideMenuItemProps) => {
  return (
    <li className="sideMenu__item" onClick={props.onClick}>
      {props.icon && <img src={props.icon} alt="logo" />}

      {props.onClick ? (
        <span onClick={props.onClick}>{props.title}</span>
      ) : (
        <NavLink
          to={props.link || "#"}
          activeClassName="active"
          activeStyle={{ textDecoration: "none" }}
        >
          {props.title}
        </NavLink>
      )}
    </li>
  );
};

SideMenu.Header = Header;
SideMenu.SubHeader = SubHeader;
SideMenu.List = List;
SideMenu.Item = Item;

export default SideMenu;
