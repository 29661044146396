import { ADD_PARTNER, SET_PARTNERS } from "../../types/types";

type InitialState = {
  partners: {
    partners: any[];
  };
};

const initialState: InitialState = {
  partners: {
    partners: [],
  },
};

type Action = {
  type: "SET_PARTNERS" | "ADD_PARTNER";
  partner?: any;
  partners?: any[];
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_PARTNERS:
      return {
        ...state,
        partners: action.partners,
      };
    case ADD_PARTNER:
      return {
        ...state,
        partners: {
          ...state.partners,
          partners: [{ ...action.partner }, ...state.partners.partners],
        },
      };
    default:
      return state;
  }
};
