import axios from "axios";

const url = process.env.REACT_APP_URL;
// const token = localStorage.getItem("koboCareControl_tk");
export const GET = (endpoint: string) => {
  return axios.get(`${url}${endpoint}`);
};
export const POST = (endpoint: string, data: any) => {
  return axios.post(`${url}${endpoint}`, data);
};

export const PUT = (endpoint: string, data: any) => {
  return axios.put(`${url}${endpoint}`, data);
};

export const DOWNLOAD = (endpoint: string, name: string) => {
  axios({
    url: `${url}${endpoint}`, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `${name}.csv`); //or any other extension
     document.body.appendChild(link);
     link.click();
  });
}
