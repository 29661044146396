import * as React from "react";
import "../Products.scss";
import MainLayout from "../../../shared/components/MainLayout/MainLayout";
import ContentLayout from "../../../shared/components/ContentLayout/ContentLayout";
import { RouteComponentProps } from "react-router-dom";
import Card from "../../../shared/components/Card/Card";
import Table from "../../../shared/components/Table/Table";
import Button from "../../../shared/components/Button/Button";
import TopContainer from "../../../shared/components/TopContainer/TopContainer";
import CardContainer from "../../../shared/components/CardContainer/CardContainer";
// import { getDash } from "../../../shared/utils/fx";
import ProductsProvider from "../ProductsProvider/ProductsProvider";
import { objInterface } from "../../../shared/utils/interfaces";

interface Props extends RouteComponentProps {
  state: objInterface;
}

const ProductList = (props: Props) => {

  return (
    <MainLayout>
      <ProductsProvider
        render={({
          loading,
          state,
          onSearch,
          tableHeadings,
          items,
          isCurrent,
          limit,
          total,
          next,
          prev,
          totalPages,
        }: any) => {
          return (
            <ContentLayout title={"Product MANAGEMENT"}>
              <TopContainer>
                <CardContainer>
                  <Card
                    className={"green-card"}
                    title={items?.products?.length}
                    description={"Products"}
                  />
                  {/* <Card
                    className={"red-card"}
                    title={getDash()}
                    description={"Deactivated"}
                  /> */}
                </CardContainer>
                <Button
                  type={"button"}
                  icon={require("../../../assets/Icons/add.svg")}
                  title="ADD PRODUCT"
                  className="button--green"
                  onClick={() => props.history.push(`/add-product`)}
                />
              </TopContainer>

              <Table
                loading={loading}
                showHelmet={true}
                headings={tableHeadings}
                paginated={totalPages > 1}
                // exportList={true}
                // onSearch={onSearch}
                onNext={next}
                onPrev={prev}
                currentPage={state.currentPage}
                totalPages={totalPages}
                searchTerm={state.searchTerm}
                data={
                  items && items.products && items.products.length
                    ? items.products
                    : []
                }
                title={"Products List"}
              />
            </ContentLayout>
          );
        }}
      />
    </MainLayout>
  );
};
export default ProductList;
