import {
  ADD_INCENTIVE,
  SET_INCENTIVES,
  UPDATE_INCENTIVE,
} from "../../types/types";

type InitialState = {
  incentives: {
    incentives: any[];
    limit: number;
    currentPage: number;
  };
};

const initialState: InitialState = {
  incentives: {
    limit: 30,
    currentPage: 1,
    incentives: [],
  },
};

type Action = {
  type: "SET_INCENTIVES" | "ADD_INCENTIVE" | "UPDATE_INCENTIVE";
  incentive?: any;
  incentives?: any[];
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_INCENTIVES:
      return {
        ...state,
        incentives: action.incentives,
      };

    case ADD_INCENTIVE:
      return {
        ...state,
        incentives: {
          ...state.incentives,
          incentives: [{ ...action.incentive }, ...state.incentives.incentives],
        },
      };
    case UPDATE_INCENTIVE:
      return {
        ...state,
        incentives: {
          ...state.incentives,
          incentives: state.incentives.incentives.map((incentive) => {
            if (incentive._id === action.incentive._id) {
              console.log(action.incentive);
              return action.incentive;
            }
            return incentive;
          }),
        },
      };
    default:
      return state;
  }
};
