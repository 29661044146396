import React from 'react';
import { objInterface } from '../../utils/interfaces';

function Popup(props: objInterface) {
    const { autocomplete, selectLocation } = props;

    if (autocomplete.length < 1) {
        return <div className='popup'>
            <div className='popup__item'>
                No location available
            </div>
        </div>
    }

    return <div className='popup'>
        {
            autocomplete?.map((val: objInterface) =>
                <div key={val.placeId} className='popup__item' onClick={() => selectLocation(val)}>
                    <p>{val.description}</p>
                </div>
            )
        }

    </div>
}

export default Popup;