import {
  ADD_PRODUCT,
  EXTEND_CONTRACT,
  SET_PRODUCTS,
  UPDATE_PRODUCT,
} from "../../types/types";

type InitialState = {
  products: {
    products: any[];
  };
};

const initialState: InitialState = {
  products: {
    products: [],
  },
};
type Action = {
  type: "ADD_PRODUCT" | "SET_PRODUCTS" | "UPDATE_PRODUCT" | "EXTEND_CONTRACT";
  product?: any;
  contract?: any;
  products?: any[];
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          products: [
            {
              ...action.product,
            },
            ...state.products.products,
          ],
        },
      };

    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          products: state.products.products.map((product) => {
            if (product._id === action.product._id) {
              return action.product;
            }
            return product;
          }),
        },
      };

    case EXTEND_CONTRACT:
      return {
        ...state,
        products: {
          ...state.products,
          products: state.products.products.map((prod) => {
            if (prod.name === action.contract.name) {
              return action.contract;
            }
            return prod;
          }),
        },
      };

    default:
      return state;
  }
};
