// import {
//   ADD_VENDOR_RECONCILIATION,
//   SET_VENDOR_RECONCILIATIONS,
// } from "../../types/types";

type InitialState = {
  vendorReconciliations: {
    vendorReconciliations: any;
  };
};

const initialState: InitialState = {
  vendorReconciliations: {
    vendorReconciliations: [],
  },
};

type Action = {
  type: "SET_VENDOR_RECONCILIATIONS" | "ADD_VENDOR_RECONCILIATION";
  vendors?: any[];
  vendorReconciliation?: any[];
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case "SET_VENDOR_RECONCILIATIONS":
      console.log("action", action);
      return {
        ...state,
        vendorReconciliations: action.vendorReconciliation,
      };

    case "ADD_VENDOR_RECONCILIATION":
      return {
        ...state,
        vendorReconciliations: {
          ...state.vendorReconciliations,
          vendorReconciliations: {
            ...state.vendorReconciliations.vendorReconciliations,
            ...action.vendorReconciliation,
          },
        },
      };
    default:
      return state;
  }
};
