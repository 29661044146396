import * as React from "react";
import "./Login.scss";
import Form from "../../shared/components/Form/Form";
import Button from "../../shared/components/Button/Button";
// import { Link } from "react-router-dom";
import FormContainer from "../../shared/components/FormContainer/FormContainer";
import Message from "../../shared/components/Message/Message";
import LoginProvider from "./LoginProvider/LoginProvider";

interface PageProps {
  email: string;
  secret: string;
  loading: boolean;
  errMsg: string;
  error: boolean;
  setSecret: (e: any) => void;
  setEmail: (e: any) => void;
  handleSubmit: (e: any) => void;
};

function Login() {
  return (
    <>
      <LoginProvider
        render={({
          email,
          secret,
          loading,
          errMsg,
          error,
          setSecret,
          setEmail,
          handleSubmit,
        }: PageProps) => {
          return (
            <FormContainer>
              <Form
                className={"form__login"}
                onSubmit={(e) => handleSubmit(e)}
                title={"Sign in"}
              >
                <Message error={error} errMsg={errMsg} />
                <Form.Input
                  onChange={(e) => setEmail(e.target.value)}
                  type={"email"}
                  value={email}
                  label={"Email"}
                />
                <Form.Input
                  type={"password"}
                  value={secret}
                  onChange={(e) => setSecret(e.target.value)}
                  label={"Password"}
                />
                {/* <div className="text-right">
                  <Link to={"/forgot-password"}>forgot password</Link>
                </div> */}
                <Button
                  loading={loading}
                  type={"submit"}
                  className={"button--green"}
                  title={"Sign In"}
                  disabled={email === ''|| secret === ''}
                />
              </Form>
            </FormContainer>
          );
        }}
      />
    </>
  );
}

export default Login;
