import * as React from "react";
import "../../../shared/components/Modal/Modal.scss";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "../../../shared/components/Form/Form";
import Button from "../../../shared/components/Button/Button";
import UsersProvider from "../UsersProvider/UsersProvider";

export interface Props {
  name?: string;
  title?: string;
  type: string;
  onClick?: () => void;
  user: any;
}

function UserModal({ title, type, onClick, user }: Props) {

  return (
    <UsersProvider
      render={({
        inputValues,
        handleChange,
        handleOnChange,
        handleEditUser,
        handleEditPassword,
        loading
      }: any) => {
        return (
          <div>
            <header className="modalHeader">
              <p className="modalHeader-heading">{title}</p>
            </header>
            <div className="modalContainer">
              <form className="modalContainer-content partner-pd">
                {type === "password" && (
                  <>
                    <Form.Input
                      name="oldPassword"
                      value={inputValues.oldPassword}
                      onChange={handleChange}
                      type={"password"}
                      className={"mr-1"}
                      label={"Old Password"}
                    />
                    <Form.Input
                      name="newPassword"
                      value={inputValues.newPassword}
                      onChange={handleChange}
                      type={"password"}
                      label={"New Password"}
                    />
                    <Form.Input
                      name="confirmPassword"
                      value={inputValues.confirmPassword}
                      onChange={handleChange}
                      type={"password"}
                      label={"Confirm Password"}
                    />
                  </>
                )}

                {/* EDIT USER */}
                {type === "user" && (
                  <>
                    <div className="d-flex align-center">
                      <Form.Input
                        name="firstName"
                        defaultValue={user?.first_name}
                        className="mr-1"
                        onChange={handleChange}
                        type={"text"}
                        label={"First name"}
                      />

                      <Form.Input
                        type={"text"}
                        name="lastName"
                        defaultValue={user?.last_name}
                        onChange={handleChange}
                        label={"Last name"}
                        className={"mr-1"}
                      />
                    </div>
                    <div className="d-flex align-center">
                      <Form.Input label={"Mobile Number"} className="mr-1">
                        <ReactPhoneInput
                          preferredCountries={["ng", "gh", "ke", "tg", "bf"]}
                          value={user?.mobile}
                          country="ng"
                          onChange={handleOnChange}
                        />
                      </Form.Input>

                      <Form.Select
                        name="role"
                        value={inputValues?.role || user?.role}
                        label={"Role "}
                        onChange={handleChange}
                        
                      >
                        <option value="">Select</option>
                        <option value="admin">Admin</option>
                        <option value="manager">Manager</option>
                        <option value="attendant">Attendant</option>
                      </Form.Select>
                    </div>

                    <Form.Input
                      type={"text"}
                      label={"Email"}
                      name="email"
                      className="mr-1"
                      defaultValue={user?.email}
                      onChange={handleChange}
                    />
                  </>
                )}
                <div className="center-button">
                  <Button
                    onClick={(e) => {
                      type === "user"
                        ? handleEditUser(e, user)
                        : handleEditPassword(e, user);
                    }}
                    type={"button"}
                    title={loading ? "LOADING" : "CONFIRM"}
                    className="button--green"
                  />
                </div>
              </form>
            </div>
          </div>
        );
      }}
    />
  );
}

export default UserModal;
