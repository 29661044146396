import {
  SET_CURRENT_PAGE,
  SET_LIMIT,
  SET_TRANSACTIONS,
} from "../../types/types";

type InitialState = {
  transactions: {
    limit: number;
    currentPage: number;
  };
};

const initialState: InitialState = {
  transactions: {
    limit: 30,
    currentPage: 1,
  },
};
type Action = {
  type: "SET_TRANSACTIONS" | "SET_LIMIT" | "SET_CURRENT_PAGE";
  transactions?: any[];
  limit?: any;
  currentPage?: any;
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
      };

    case SET_LIMIT:
      return {
        ...state,
          transactions:{
              ...state.transactions,
              limit: action.limit,
          }
      };

    case SET_CURRENT_PAGE:
        console.log('action',action);
      return {
        ...state,
          transactions:{
              ...state.transactions,
              currentPage: action.currentPage,
          }

      };

    default:
      return state;
  }
};
