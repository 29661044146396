export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ADD_STATION = "ADD_STATION";
export const SET_STATIONS = "SET_STATIONS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ADD_USER = "ADD_USER";
export const SET_USERS = "SET_USERS";
export const SET_APPROVALS = "SET_APPROVALS";
export const ADD_APPROVAL = "ADD_APPROVAL";
export const REJECT_APPROVAL = "REJECT_APPROVAL";
export const SET_CONTRACTS = "SET_CONTRACTS";
export const ADD_CONTRACT = "ADD_CONTRACT";
export const REJECT_CONTRACT = "REJECT_CONTRACT";
export const SET_VENDORS = "SET_VENDORS";
export const ADD_VENDOR = "ADD_VENDOR";
export const SAVE_VENDOR = "SAVE_VENDOR";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const SET_CARDS = "SET_CARDS";
export const ADD_CARD = "ADD_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const ACCEPT_PRICE_APPROVAL = "ACCEPT_PRICE_APPROVAL";
export const REJECT_PRICE_APPROVAL = "REJECT_PRICE_APPROVAL";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_TRIP_TRANSACTIONS = "SET_TRIP_TRANSACTIONS";
export const SET_LIMIT = "SET_LIMIT";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const EXTEND_CONTRACT = "EXTEND_CONTRACT";
export const ADD_CARD_PRODUCT = "ADD_CARD_PRODUCT";
export const TOP_SELLING_PRODUCT = "TOP_SELLING_PRODUCT";
export const TOP_SELLING_VENDOR = "TOP_SELLING_VENDOR";
export const TOTAL_AMOUNT_SOLD = "TOTAL_AMOUNT_SOLD";
export const MONTHLY_SALES = "MONTHLY_SALES";
export const TOP_STATIONS = "TOP_STATIONS";
export const GET_WALLET_REQUEST = "GET_WALLET_REQUEST";
export const ACCEPT_WALLET_REQUEST = "ACCEPT_WALLET_REQUEST";
export const FILTER_DATE = "FILTER_DATE";
export const FILTER_USER_TYPE = "FILTER_USER_TYPE";
export const SET_TRUCKS = "SET_TRUCKS";
export const ADD_TRUCK = "ADD_TRUCK";
export const SET_PARTNERS = "SET_PARTNERS";
export const ADD_PARTNER = "ADD_PARTNER";
export const SET_DRIVERS = "SET_DRIVERS";
export const ADD_DRIVER = "ADD_DRIVER";
export const FETCH_COUNTRY = "FETCH_COUNTRY";
export const ADD_INCENTIVE = "ADD_INCENTIVE";
export const SET_INCENTIVES = "SET_INCENTIVES";
export const UPDATE_INCENTIVE = "UPDATE_INCENTIVE";
export const ADD_VOUCHER = "ADD_VOUCHER";
export const SET_VOUCHERS = "SET_VOUCHERS";
export const SET_VENDOR_RECONCILIATIONS = "SET_VENDOR_RECONCILIATIONS";
export const ADD_VENDOR_RECONCILIATION = "ADD_VENDOR_RECONCILIATION";
export const SET_PRODUCT_RECONCILIATIONS = "SET_PRODUCT_RECONCILIATIONS";
export const ADD_PRODUCT_RECONCILIATION = "ADD_PRODUCT_RECONCILIATION";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SET_SUBSCRIBED = "SET_SUBSCRIBED";
export const ADD_SUBSCRIBED = "ADD_SUBSCRIBED";
export const UPDATE_SUBSCRIBED = "UPDATE_SUBSCRIBED";
