import React, { ReactNode } from "react";
import "./InfoBox.scss";

type InfoBoxProps = {
  vendor?: string;
  title: string;
  contactPerson?: string;
  phoneNumber?: string;
  email?: string;
  location?: string;
  country?: string;
  children?: ReactNode;
  onEdit?: (e: any) => void;
  editText?: string;
  
};

function InfoBox({
  vendor,
  title,
  contactPerson,
  phoneNumber,
  email,
  location,
  country,
  children,
  onEdit,
  editText,
}: InfoBoxProps) {
  return (
    <div className="infoBox">
      <div className={"infoBox__header"}>
        <h4>{title}</h4>
        {onEdit ? (
          <strong onClick={(e) => onEdit && onEdit(e)}>{editText}</strong>
        ) : (
          ""
        )}
      </div>
      <div className={"infoBox__body"}>
        {vendor ? (
          <div>
            <span>Vendor</span>
            <span>{vendor}</span>
          </div>
        ) : (
          ""
        )}
        {contactPerson ? (
          <div>
            <span>Contact Person</span>
            <span>{contactPerson}</span>
          </div>
        ) : (
          ""
        )}

        {phoneNumber ? (
          <div>
            <span>Phone Number</span>
            <span>{phoneNumber}</span>
          </div>
        ) : (
          ""
        )}
        {email ? (
          <div>
            <span>Email</span>
            <span>{email}</span>
          </div>
        ) : (
          ""
        )}
        {location ? (
          <div>
            <span>location</span>
            <span>{location}</span>
          </div>
        ) : (
          ""
        )}

        {country ? (
          <div>
            <span>Country</span>
            <span>{country}</span>
          </div>
        ) : (
          ""
        )}
        {children}
      </div>
    </div>
  );
}

export default InfoBox;
