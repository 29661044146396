import React, { useState, useEffect } from 'react';
import './SingleStation.scss';
import InfoBox from '../../../shared/components/InfoBox/InfoBox';
import { useParams, useHistory } from 'react-router';
import { objInterface } from '../../../shared/utils/interfaces';
import Card from '../../../shared/components/Card/Card';
// import CardContainer from '../../../shared/components/CardContainer/CardContainer';
import { GET } from '../../../core/config/Api';
import { useCallback } from 'react';
import Modal from '../../../shared/components/Modal/Modal';
import StationModal from './StationModal';

function SingleStation(props: any) {
    const { id } = useParams<{ [key: string]: string }>();
    const [station, setStation] = useState<objInterface>({});
    const [stationProducts, setStationProducts] = useState<Array<objInterface>>([]);
    const [show, setShow] = useState(false);
    const [prod, setProd] = useState<objInterface | undefined>({});
    const [activate, setActivate] = useState(false);
    const [deactivate, setDeactivate] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [edit, setEdit] = useState(false);


    const { goBack } = useHistory();

    const { vendor } = props;

    const getStation = useCallback(async () => {
        const res = await GET(`vendor/${vendor.id}/station/${id}`);
        setStation(prev => ({ ...prev, ...res.data.data.station }));
    }, [id, vendor.id])


    useEffect(() => {
        if (id) {
            getStation();

        }
    }, [getStation, id])

    const getStationProducts = useCallback(
        async () => {
            try {
                const prods = await GET(`product/${vendor.id}/station/${id}`);
                setStationProducts(prods.data.data.products);

            } catch (error) {

            }
        },
        [id, vendor.id]
    )

    useEffect(() => {
        getStationProducts();
    }, [getStationProducts])

    const closeModal = () => {
        setShow(false);
        setDeactivate(false);
        setActivate(false);
        setEdit(false);
        setCancel(false);
    };

    const viewProd = (prod: objInterface) => {
        setProd(prod);
        setShow(true);

    }

    const showActivate = (prod: objInterface) => {
        setProd(prod);
        setActivate(true);

    }

    const showDeactivate = (prod: objInterface) => {
        setProd(prod);
        setDeactivate(true);

    }

    const cancelPrice = (prod: objInterface) => {
        setProd(prod);
        setCancel(true);

    }

    return <div>
        <button className='back' onClick={goBack}>
            <i className="fas fa-angle-left" /> <span>Back</span>
        </button>
        <div className="container">

            <div className="container__station">
                <p>Station code</p>
                <h5>{station?.code}</h5>
                <div className="container__station-card">
                    <Card
                        className={"green-card"}
                        title={`${station?.pass_code || '---'}`}
                        description={"Pass Code"}
                        icon={require("../../../assets/images/Box-white.svg")}
                    />
                </div>
            </div>
            <div className="container__general">
                <InfoBox
                    title={"General Information"}
                    editText={"Edit"}
                    onEdit={() => setEdit(true)}
                >
                    <div>
                        <span>Station Name</span>
                        <span>{station?.name}</span>
                    </div>
                    <div>
                        <span>Address</span>
                        <span>{station?.location}</span>
                    </div>
                    <div>
                        <span>Nearest Landmark</span>
                        <span>{station?.land_mark || 'NA'}</span>

                    </div>
                    <div>
                        <span>Station Manager</span>
                        <span>{station?.manager?.first_name ?? 'NA'} {station?.manager?.last_name}</span>

                    </div>
                    <div>
                        <span>Station Manager&apos;s Phone</span>
                        <span>{station?.manager?.mobile ?? 'NA'}</span>

                    </div>
                </InfoBox>
                <div>
                    <Card
                        className={"torquoise-card"}
                        title={`${stationProducts?.length}`}
                        description={"Products"}
                        icon={require("../../../assets/images/Box-white.svg")}
                    />
                    {/* <CardContainer>
                        <Card
                            className={"yellow-card"}
                            title={"2"}
                            description={"Users"}
                            icon={require("../../../assets/images/user.svg")}

                        />
                    </CardContainer> */}
                </div>

                {stationProducts.length > 0 &&
                    <div className="container__general-products">
                        <div className="container__general-products--title">
                            <h5>Station Products</h5>
                            {/* <button>ADD STATION PRODUCT</button> */}
                        </div>
                        {
                            stationProducts?.map((prod: objInterface) =>
                                <div className={`container__general-products--${prod.rateApprovalStatus === 'Pending' ? 'pending' : 'item'}`} key={prod._id}>
                                    <div className="container__general-products--item-top">
                                        <div className="container__general-products--item-top--name">
                                            <p>{prod.name}</p>
                                            <span>{prod.unit}</span>
                                        </div>
                                        {
                                            vendor.admin.role.toLowerCase() !== 'attendant' && 
                                            <div className={`container__general-products--${prod.rateApprovalStatus === 'Pending' ? 'pending' : 'item'}-top--actions`}>
                                                {prod.rateApprovalStatus === 'Pending' && <button onClick={() => cancelPrice(prod)} className="cancel">CANCEL PRICE UPDATE</button>}
                                                {prod.activated && <button onClick={() => showDeactivate(prod)} className="deactive">DEACTIVATE</button>}
                                                {!prod.activated && <button onClick={() => showActivate(prod)} className="activate">ACTIVATE</button>}

                                                <button onClick={() => viewProd(prod)} className="active">EDIT PRODUCT</button>

                                            </div>
                                        }
                                    </div>

                                    <div className="container__general-products--item-bottom">
                                        <div className="container__general-products--item-bottom--price">
                                            <small>Station selling price</small>
                                            <div>
                                                {prod?.currency} {prod.rate}
                                            </div>
                                        </div>
                                        <div className="container__general-products--item-bottom--amount">
                                            <small>Total amount sold</small>
                                            <div>
                                                {prod?.currency} {prod.totalValueSold}
                                            </div>
                                        </div>
                                        <div className="container__general-products--item-bottom--qty">
                                            <small>Total quantity sold</small>
                                            <div>
                                                {prod.totalQuantitiesSold}
                                            </div>
                                        </div>

                                    </div>
                                    {prod.rateApprovalStatus === 'Pending' &&
                                        <div className="container__general-products--pending-update">
                                            <div className="container__general-products--pending-update--rate">
                                                <small>Pending station selling price</small>
                                                <div>
                                                    {prod?.currency} {prod.pendingRate}
                                                </div>
                                            </div>
                                            <div className="pending">
                                                <img src={require('../../../assets/images/alert-triangle.svg')} alt="alert" />
                                                <p>Product price update is awaiting approval</p>
                                            </div>

                                        </div>}



                                </div>
                            )
                        }
                    </div>}
            </div>
            <div />

        </div>
        {show && (
            <Modal
                onNullClick={(e) => e.stopPropagation()}
                onClick={closeModal}
            >

                <StationModal
                    title="Update Price"
                    type="edit"
                    station={station}
                    product={prod}
                />
            </Modal>
        )
        }

        {activate && (
            <Modal
                onNullClick={(e) => e.stopPropagation()}
                onClick={closeModal}
            >

                <StationModal
                    title={`Activate ${prod?.name}`}
                    type="activate"
                    station={station}
                    product={prod}
                    closeModal={closeModal}
                />
            </Modal>
        )
        }

        {deactivate && (
            <Modal
                onNullClick={(e) => e.stopPropagation()}
                onClick={closeModal}
            >

                <StationModal
                    title={`Deactivate ${prod?.name}`}
                    type="deactivate"
                    station={station}
                    product={prod}
                    closeModal={closeModal}
                />
            </Modal>
        )
        }

        {edit && (
            <Modal
                onNullClick={(e) => e.stopPropagation()}
                onClick={closeModal}
            >

                <StationModal
                    title={`Edit ${station?.name}`}
                    type="editStation"
                    station={station}
                    closeModal={closeModal}
                />
            </Modal>
        )
        }

        {cancel && (
            <Modal
                onNullClick={(e) => e.stopPropagation()}
                onClick={closeModal}
            >

                <StationModal
                    title={`Cancel price update request for ${prod?.name}`}
                    type="cancel"
                    station={station}
                    product={prod}
                    closeModal={closeModal}
                />
            </Modal>
        )
        }
    </div>

}

export default SingleStation;