import * as React from "react";
import { ReactNode } from "react";
import "./Table.scss";
import { uuid } from "../../utils/fx";
import Button from "../Button/Button";
import TableSearch from "./TableSearch";
import Empty from "../Empty/Empty";
import { Link } from "react-router-dom";

export interface TableProps {
  className?: string;
  title?: string;
  topTab?: ReactNode;
  icon?: string;
  loading?: boolean;
  headings: {key: string, title: string}[];
  data: any[];
  showHelmet?: boolean;
  onExportList?: () => void;
  onClick?: () => void;
  onFilter?: () => void;
  onResetFilter?: () => void;
  onSearch?: (e: any) => void;
  onSearchChange?: (e: any) => void;
  searchTerm?: string;
  onNext?: () => void;
  onPrev?: () => void;
  totalPages?: string | number;
  currentPage?: string | number;
  paginated?: boolean;
  exportList?: boolean;
  exportLink?: string;
  onExport?: () => void;
  type?: string;
  url?: string;
  options?: () => void;
  openBox?: boolean;
}

export interface TableHeadingProps {
  headings: {key: string, title: string}[];
  onClick?: () => void;
}

export interface TableBodyProps {
  data: any[];
  headings: any[];
  onClick?: () => void;
  options?: (data?: any) => void;
  toggle?: any;
  openBox?: boolean;
  showPartnerModal?: any;
}

const renderNestedObj = (key: string, data: any) => {
  let arr = key.split(".");
  return data[arr[0]][arr[1]];
};

const Body = (props: TableBodyProps) => {
  return (
    <tbody className="tableBody">
      {props.data.length
        ? props.data.map((d) => {
            return (
              <tr className="tableRow" key={uuid()}>
                {props.headings.map((col) => {
                  if (col.key === "options") {
                    return (
                      <td key={uuid()} className="tableData action-bg">
                        <div className="tableItems">
                          {col.formatter ? (
                            col.formatter(d)
                          ) : (
                            <img
                              onClick={() => col.onClick && col.onClick(d)}
                              src={require("../../../assets/images/option.svg")}
                              alt=""
                            />
                          )}
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td className="tableData" key={uuid()}>
                      <div className="tableItems"
                        onClick={() => col.onClick && col.onClick(d)}>
                        <div
                          className="tableItem"
                          
                        >
                          {col.formatter
                            ? col.formatter(d)
                            : col && col.key && col.key.includes(".")
                            ? renderNestedObj(col.key, d)
                            : d[col.key]}
                        </div>
                      </div>
                    </td>
                  );
                })}
                {props.toggle ? (
                  <td key={uuid()} className="tableData action-bg">
                    <div className="tableItems">
                      <img
                        onClick={() => props.options && props.options()}
                        src={require("../../../assets/images/option.svg")}
                        alt=""
                      />
                    </div>
                  </td>
                ) : (
                  null
                )}
              </tr>
            );
          })
        : null}
    </tbody>
  );
};

function Table(props: TableProps) {
  return (
    <div className={`tableContainer ${props.className ? props.className : ""}`}>
      {props.topTab ? (
        <div className="tableContainer__helmet-toggle pointer">
          {props.topTab}
        </div>
      ) : (
        ""
      )}
      {props.type === "partners" && (
        <div className="tableContainer__helmet-toggle pointer">
          <Link to="/onboarded-partners">
            <h1
              className={`tableTitle__toggle ${
                props.url === "/onboarded-partners" ? "activeTab" : ""
              }`}
            >
              Onboarded Partners
            </h1>
          </Link>
          <Link to="/subscribed-partners">
            <h1
              className={`tableTitle__toggle ${
                props.url === "/subscribed-partners" ? "activeTab" : ""
              }`}
            >
              Subscribed Partners
            </h1>
          </Link>
        </div>
      )}
      {props.type === "leads" && (
        <div className="tableContainer__helmet-toggle pointer">
          <Link to="/truck-management">
            <h1
              className={`tableTitle__toggle ${
                props.url === "/truck-management" ? "activeTab" : ""
              }`}
            >
              Onboarded Trucks
            </h1>
          </Link>
          <Link to="/drivers">
            <h1
              className={`tableTitle__toggle ${
                props.url === "/drivers" ? "activeTab" : ""
              }`}
            >
              Onboarded Drivers
            </h1>
          </Link>
        </div>
      )}

      {props.showHelmet ? (
        <div
          className={`tableContainer__helmet ${
            !props.exportLink && !props.onExportList && !props.onFilter
              ? "justify-end"
              : ""
          }`}
        >
          {props.data.length > 0 && props.exportLink && (
            <a
              href={props.exportLink}
              rel="noopener noreferrer"
              className="button--outline button--sm  pointer"
            >
              Export List
            </a>
          )}
          {props.data.length > 0 && props.onExportList ? (
            <div
              onClick={props.onExportList}
              className="button--outline button--sm  pointer"
            >
              Export List
            </div>
          ) : (
            ""
          )}
          {props.title ? <h2 className="tableTitle">{props.title}</h2> : ""}

          {props.onFilter ? (
            <>
              <Button
                type={"button"}
                className="button--outline button--sm"
                onClick={props.onFilter}
                title="reset filter"
              />
              <Button
                type={"button"}
                className="button--outline button--sm"
                onClick={props.onResetFilter}
                title="Filters"
              />
            </>
          ) : (
            ""
          )}

          {props.data.length > 0 && props.onSearch ? (
            <TableSearch
              // onchange={props.onSearchChange}
              searchTerm={props.searchTerm}
              onSearch={props.onSearch}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {props.loading ? (
        <Empty loading={props.loading} />
      ) : props.data && props.data.length ? (
        <table>
          <Header headings={props.headings} />
          <Body data={props.data} headings={props.headings} />
        </table>
      ) : (
        <Empty description={"No Data at the moment"} />
      )}

      {props.paginated || props.exportList ? (
        <Footer>
          {/*{props.exportList && (*/}
          {/*  <a*/}
          {/*    href={props.exportLink}*/}
          {/*    rel="noopener noreferrer"*/}
          {/*    className="button--outline button--sm  pointer"*/}
          {/*  >*/}
          {/*    Export List*/}
          {/*  </a>*/}
          {/*)}*/}
          {props.paginated && (
            <Pagination
              currentPage={props.currentPage}
              totalPages={props.totalPages}
              onNext={props.onNext}
              onPrev={props.onPrev}
            />
          )}
        </Footer>
      ) : (
        ""
      )}
    </div>
  );
}

const Header = (props: TableHeadingProps) => {
  return (
    <thead className="tableHeading">
      <tr className="tableRow">
        {props.headings.map((heading: any) => (
          <th className="tableHeader" key={uuid()}>
            <span className="tableTitle">{heading.title}</span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface PaginationProps {
  onPrev?: () => void;
  onNext?: () => void;
  totalPages?: string | number;
  currentPage?: string | number;
};

const Pagination = ({
  onPrev,
  onNext,
  currentPage,
  totalPages,
}: PaginationProps) => {
  return (
    <div className="table-footer-new">
      {currentPage && currentPage > 1 && (
        <i
          onClick={() => onPrev && onPrev()}
          className="fas fa-chevron-left pointer control"
        />
      )}
      &nbsp;
      <span className="table-footer-new__current-page">{currentPage}</span>
      &nbsp; of {totalPages} &nbsp;
      {currentPage !== totalPages && (
        <i
          onClick={() => onNext && onNext()}
          className="fas fa-chevron-right pointer control"
        />
      )}
    </div>
  );
};

type FooterProps = {
  children: ReactNode;
};

const Footer = ({ children }: FooterProps) => {
  return (
    <div className="tableContainer__footer d-flex align-center justify-end">
      {children}
    </div>
  );
};

Table.Header = Header;
Table.Body = Body;
Table.Pagination = Pagination;

export default Table;
