import {ADD_SUBSCRIBED, SET_SUBSCRIBED, UPDATE_SUBSCRIBED,} from "../../types/types";

type InitialState = {
    partners: {
        partners: any[];
    };
};
type Action = {
    type: "SET_SUBSCRIBED" | "ADD_SUBSCRIBED" | "UPDATE_SUBSCRIBED";
    partner?: any;
    partners?: any[];
};

const initialState: InitialState = {
    partners: {
        partners: [],
    },
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_SUBSCRIBED:
            return {
                ...state,
                partners: action.partners,
            };
        case ADD_SUBSCRIBED:
            return {
                ...state,
                partners: {
                    ...state.partners,
                    partners: [{...action.partner}, ...state.partners.partners],
                },
            };
        case UPDATE_SUBSCRIBED:
            return {
                ...state,
                partners: {
                    ...state.partners,
                    partners: state.partners.partners.map((partner) => {
                        if (partner.id === action.partner.id) {
                            console.log(action.partner);
                            return action.partner;
                        }
                        return partner;
                    }),
                },
            };
        default:
            return state;
    }
};
