import * as React from "react";
import { withToastManager } from "react-toast-notifications";
import "../../../shared/components/Modal/Modal.scss";
import Form from "../../../shared/components/Form/Form";
import Button from "../../../shared/components/Button/Button";
import { objInterface } from '../../../shared/utils/interfaces';
import { PUT } from "../../../core/config/Api";

export interface Props {
  title?: string;
  type: string;
  onClick?: () => void;
  station: objInterface;
  product: objInterface | undefined;
  toastManager?: any;
  closeModal?: () => void;


}

function StationModal({ title, type, station, product, toastManager, closeModal }: Props) {

  const [rate, setRate] = React.useState('');
  const [data, setData] = React.useState<objInterface>({});

  const toastMessage = (message: string, type: string) => {
    toastManager.add(message, {
      appearance: type,
    });
  };

  const handleActivate = async (e: React.ChangeEvent<HTMLInputElement>, station: objInterface) => {
    try {
      const data = { active: true };
      const res = await PUT(`product/vendor/${station?.vendor_id}/product/${product?.productId}/station/${station?.id}/activate`, data);
      if (res) {
        toastMessage('Succesfully Activated this product', 'success');

      }
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch(error:any) {
      toastMessage(error.response.data.message, 'error');
    }

  }

  const handleDeactivate = async (e: React.ChangeEvent<HTMLInputElement>, station: objInterface) => {

    try {
      const data = { active: false };
      const res = await PUT(`product/vendor/${station?.vendor_id}/product/${product?.productId}/station/${station?.id}/activate`, data);
      if (res) {
        toastMessage('Succesfully Deactivated this product', 'success');
      }
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error:any) {
      toastMessage(error.response.data.message, 'error');
    }
  }

  const handleEditProduct = async (e: any, station: objInterface) => {
    try {
      const data = {
        rate: +rate,
      }
      const res = await PUT(`product/vendor/${station?.vendor_id}/station/${station?.id}/product/${product?.productId}/updateRate`, data);
      if (res) {
        toastMessage('Succesfully updated price', 'success');

      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch(error:any) {
      toastMessage(error.response.data.message, 'error');
    }
  }

  const handleStationChange = (e: { target: HTMLInputElement }) => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, ...{ [name]: value } }))

  }

  const handleStationUpdate = async (e: any, station: objInterface) => {
    try {

      const res = await PUT(`vendor/station/${station?.id}`, data);
      if (res) {
        toastMessage('Succesfully updated station detail', 'success');

      }

      window.location.reload();
    } catch(error:any) {
      toastMessage(error.response.data.message, 'error');
    }
  }

  const handleCancelPriceUpdate = async (e: React.ChangeEvent<HTMLInputElement>, station: objInterface) => {

    try {
      const res = await PUT(`product/vendor/${station?.vendor_id}/station/${station?.id}/product/${product?.productId}/cancelRateUpdate`, {});
      if (res) {
        toastMessage('Succesfully cancelled price update', 'success');
      }
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch(error:any) {
      toastMessage(error.response.data.message, 'error');
    }
  }

  return (
    <div>
      <header className="modalHeader">
        <p className="modalHeader-heading">{title}</p>
        {/* <p className="modalHeader-heading pointer red" onClick={onClick}>
                CLOSE
              </p> */}
      </header>
      <div className="modalContainer">
        <form className="modalContainer-content partner-pd">
          {type === "edit" && (
            <>
              <div>
                <span>Product</span>
                <span>{product?.name}</span>
              </div>

              <div>
                <span>Station</span>
                <span>{station?.name}</span>
              </div>

              <div>
                <span>Global Rate</span>
                <span>{product?.currency} {product?.rate}</span>
              </div>
              <div>
                <span>Current Station Rate</span>
                <span className='green'>{product?.currency} {product?.rate}</span>
              </div>
              <Form.Input
                type={"text"}
                label={"Set new station rate"}
                name="rate"
                className="mr-1"
                value={rate}
                onChange={e => setRate(e.target.value)}
              />
            </>
          )}

          {/* EDIT USER */}
          {type === "activate" && (
            <div className='warning'>
              <p>
                Are you sure you want to Activate {product?.name}?
                    </p>
            </div>
          )}
          {type === "deactivate" && (
            <div className='warning'>
              <p>
                Are you sure you want to Deactivate {product?.name}?
                    </p>
            </div>
          )}
          {type === "cancel" && (
            <div className='warning'>
              <p>
                Are you sure you want to Cancel price update for {product?.name}?
              </p>
            </div>
          )}

          {type === "editStation" && (
            <>
              <Form.Input
                type={"text"}
                label={"Station Name"}
                name="name"
                className="mr-1"
                defaultValue={station.name}
                onChange={handleStationChange}
              />
              <Form.Input
                type={"text"}
                label={"Station Location"}
                name="location"
                className="mr-1"
                defaultValue={station.location}
                onChange={handleStationChange}
              />
              <Form.Input
                type={"text"}
                label={"Nearest Landmark"}
                name="land_mark"
                className="mr-1"
                defaultValue={station.land_mark}
                onChange={handleStationChange}
              />
            </>
          )}
          <div className="center-button">
            {closeModal && <Button
              onClick={closeModal}
              type={"button"}
              title="CANCEL"
              className="button--red"
            />}
            <Button
              onClick={(e) => {
                type === "edit"
                  ? handleEditProduct(e, station)
                  : (type === 'activate' ? handleActivate(e, station) 
                  : (type === 'deactivate' ? handleDeactivate(e, station) 
                  : (type=== 'cancel') ? handleCancelPriceUpdate(e, station) : handleStationUpdate(e, station)));
              }}
              type={"button"}
              title="CONFIRM"
              className="button--green"
            />
          </div>
        </form>
      </div>
    </div>
  );
}


export default withToastManager(StationModal);
