import * as React from "react";
import "./Step.scss";

export interface IProps {
  className?: string;
  steps: boolean[];
}

function Step(props: IProps) {
  return (
    <div className="steps">
      {props.steps.length
        ? props.steps.map((step, i) => (
            <span key={`${step}-${i}`} className={`steps-item ${step ? "" : "disabled"}`} />
          ))
        : ""}
    </div>
  );
}

export default Step;
