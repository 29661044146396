import {
  ADD_VENDOR,
  SAVE_VENDOR,
  SET_VENDORS,
  UPDATE_VENDOR,
} from "../../types/types";

type InitialState = {
  vendors: {
    vendors: any[];
    limit: number;
    currentPage: number;
  };
  vendor: any;
};
const initialState: InitialState = {
  vendors: {
    limit: 30,
    currentPage: 1,
    vendors: [],
  },
  vendor: {},
};

type Action = {
  type: "ADD_VENDOR" | "SAVE_VENDOR" | "SET_VENDORS" | "UPDATE_VENDOR";
  vendor: any;
  vendors: any[];
};
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ADD_VENDOR:
      return {
        ...state,
        // updating nested data is that every level of nesting must be copied and updated appropriately
        vendors: {
          ...state.vendors,
          //one extra level
          vendors: [
            {
              ...action.vendor,
            },

            ...state.vendors.vendors,
          ],
        },
      };

    case SET_VENDORS:
      return {
        ...state,
        vendors: action.vendors,
      };

    case SAVE_VENDOR:
      return {
        ...state,
        vendor: action.vendor,
      };

    case UPDATE_VENDOR:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          vendors: state.vendors.vendors.map((vendor) => {
            if (vendor.id === action.vendor.id) {
              return action.vendor;
            }
            return vendor;
          }),
        },
      };

    default:
      return state;
  }
};
