import React, { ReactNode } from "react";
import "./AsideMenu.scss";
import { Link } from "react-router-dom";

type AsideMenuProps = {
  title?: string;
  subTitle?: string;
  className?: string;
  children?: ReactNode;
};

function AsideMenu(props: AsideMenuProps) {
  return (
    <div className={`asideMenu ${props?.className}`}>
      {props.title ? (
        <div className="asideMenu__heading">
          <small>{props.title}</small>
          {props.subTitle ? <p>{props.subTitle}</p> : ""}
        </div>
      ) : (
        ""
      )}
      <ul className="asideMenu__body">{props.children}</ul>
    </div>
  );
}

type MenuItemProps = {
  to?: string;
  onClick?: (e: any) => void;
  title: string;
  icon: string;
};


const MenuItem = (props: MenuItemProps) => {
  return (
    <li className="pointer">
      <img src={props.icon} alt="aside menu" />
      {props.to ? <Link to={props.to}>{props.title}</Link> : ""}
      {props.onClick ? (
        <a href='#!' onClick={(e) => props.onClick && props.onClick(e)}>{props.title}</a>
      ) : (
        ""
      )}
    </li>
  );
};

AsideMenu.MenuItem = MenuItem;
export default AsideMenu;
