import * as React from "react";
import { ReactNode } from "react";
// import { NavLink } from "react-router-dom";
import "./ContentLayout.scss";
import NavBar from "../NavBar/NavBar";
import AuthProvider from "../../providers/AuthProvider/AuthProvider";

type ContentProps = {
  children: ReactNode;
  className?: string;
  user?: any;
  title: string;
};

function ContentLayout({ className, children, user, title }: ContentProps) {
  return (
    <main className={`content ${className || ""}`}>
      {/* <div className="navBg"> */}
      <NavBar>
        <NavBar.Title title={title} />
        <NavBar.Nav>
          <NavBar.NavItem className={"flex-grow-0"}>
            <NavBar.DropDown>
              {/* <img
                src={require("../../../assets/images/Notification.svg")}
                alt=""
              /> */}
              {/*<NavBar.DropDownMenu className={"notification"}>*/}
              {/*  <li>Notification</li>*/}
              {/*  <li>*/}
              {/*    <h4>Trip Delivery</h4>*/}
              {/*    <small>*/}
              {/*      Trip 12038585930 has been delivered successfully.*/}
              {/*    </small>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <h4>Trip Delivery</h4>*/}
              {/*    <small>*/}
              {/*      Trip 12038585930 has been delivered successfully.*/}
              {/*    </small>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <NavLink to={"/notifications"}>See all</NavLink>*/}
              {/*  </li>*/}
              {/*</NavBar.DropDownMenu>*/}
            </NavBar.DropDown>
          </NavBar.NavItem>
          <NavBar.NavItem className={"flex-grow-1 justify-end"}>
            <NavBar.DropDown>
              <div className="d-flex align-center">
                <div className="profile__section">
                  <h6>
                    {user && user.first_name ? user.first_name : "Not "}{" "}
                    {user && user.last_name ? user.last_name : "Available"}
                  </h6>
                  <p>
                    {user && user.user_type ? user.user_type : "Not available"}
                  </p>
                </div>
                <div className="d-flex align-center">
                  <div className="avatar">
                    <img
                      src={require("../../../assets/images/user.svg")}
                      alt="user"
                    />
                  </div>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 172 172"
                    style={{ fill: "#000000" }}
                  >
                    <g
                      fill="none"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                      style={{ mixBlendMode: "normal" }}
                    >
                      <path d="M0,172v-172h172v172z" fill="none" />
                      <g fill="#3453a6">
                        <path d="M86,100.84217l-27.38383,-27.38383c-2.967,-2.967 -7.783,-2.967 -10.75,0v0c-2.967,2.967 -2.967,7.783 0,10.75l33.067,33.067c2.80217,2.80217 7.33867,2.80217 10.13367,0l33.067,-33.067c2.967,-2.967 2.967,-7.783 0,-10.75v0c-2.967,-2.967 -7.783,-2.967 -10.75,0z" />
                      </g>
                    </g>
                  </svg> */}
                </div>
              </div>
              {/*<NavBar.DropDownMenu>*/}
              {/*  <li>*/}
              {/*    <NavLink to={"/profile"}>Profile</NavLink>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <NavLink to={"/settings"}>Settings</NavLink>*/}
              {/*  </li>*/}
              {/*</NavBar.DropDownMenu>*/}
            </NavBar.DropDown>
          </NavBar.NavItem>
          <NavBar.NavItem className={"flex-grow-0"}>
            <NavBar.DropDown>
              {/* <div className="d-flex align-center">
                <span className="lang__text">EN</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 172 172"
                  style={{ fill: "#000000" }}
                >
                  <g
                    fill="none"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <path d="M0,172v-172h172v172z" fill="none" />
                    <g fill="#3453a6">
                      <path d="M86,100.84217l-27.38383,-27.38383c-2.967,-2.967 -7.783,-2.967 -10.75,0v0c-2.967,2.967 -2.967,7.783 0,10.75l33.067,33.067c2.80217,2.80217 7.33867,2.80217 10.13367,0l33.067,-33.067c2.967,-2.967 2.967,-7.783 0,-10.75v0c-2.967,-2.967 -7.783,-2.967 -10.75,0z" />
                    </g>
                  </g>
                </svg>
              </div> */}
              {/*<NavBar.DropDownMenu>*/}
              {/*  <li>*/}
              {/*    <span>English</span>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <span>French</span>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <span>Swailli</span>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <span>Hausa</span>*/}
              {/*  </li>*/}
              {/*  <li>*/}
              {/*    <span>Portugese</span>*/}
              {/*  </li>*/}
              {/*</NavBar.DropDownMenu>*/}
            </NavBar.DropDown>
          </NavBar.NavItem>
        </NavBar.Nav>
      </NavBar>
      {/* </div> */}
      {children}
    </main>
  );
}

export default AuthProvider(ContentLayout);
