import * as React from 'react'
import { ReactNode } from 'react'
import './FormContainer.scss'
import Footer from '../Footer/Footer';



type FormContainerProps = {
  children: ReactNode
}

function FormContainer(props: FormContainerProps) {
 const  logo = require('../../../assets/images/logo.svg')
  return (
    <div className="login d-flex flex-column align-center justify-center">
      <div className="form__container">
        <img src={logo?.default} alt="logo" />
        <p className="mt-1">KoboCare Vendor Dashboard</p>
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default FormContainer
