
import React, { useState, useCallback, useEffect } from "react";
import axios  from 'axios';
import { withToastManager } from "react-toast-notifications";
// import "../Vendor.scss";
import Card from "../../shared/components/Card/Card";
// import Button from "../../shared/components/Button/Button";
import Modal from "../../shared/components/Modal/Modal";
import Form from "../../shared/components/Form/Form";
// import Spinner from "../../shared/components/Spinner/Spinner";
import AsideMenu from "../../shared/components/AsideMenu/AsideMenu";
// import Toggle from "../../shared/components/Toggle/Toggle";
import InfoBox from "../../shared/components/InfoBox/InfoBox";
// import Empty from "../../shared/components/Empty/Empty";
import MainLayout from "../../shared/components/MainLayout/MainLayout";
import ContentLayout from "../../shared/components/ContentLayout/ContentLayout";
import { connect } from 'react-redux';
import AuthProvider from '../../shared/providers/AuthProvider/AuthProvider';
import { objInterface } from '../../shared/utils/interfaces';
import { PUT, GET } from '../../core/config/Api';
import { useHistory } from 'react-router';
import ImageUploader from '../../shared/components/ImageUploader/ImageUploader';
import { formatWord, sortArray } from "../../shared/utils/fx";
import { setCurrentUser } from '../../store/actions/auth/auth';
import store from '../../store/store';


function Profile(props: any) {

    const { user: { vendor } } = props;
    const [edit, setEdit] = useState(false);
    const [upload, setUpload] = useState(false);
    const { goBack } = useHistory();
    const [countries, setCountries] = useState<Array<objInterface>>([]);
    const [preview, setPreview] = useState('');
    const [cacPrev, setCacPrev] = useState('');
    const [progress, setProgress] = useState(0);
    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState('');

    const [data, setData] = useState<objInterface>({
        name: vendor.name,
        country: vendor.country,
        location: vendor.location,
        reg_number: vendor.reg_number,
    });
    const getCountry = useCallback(async () => {
        try {
            const res = await GET('route/country');

            setCountries(res.data.data.countries)
        } catch (error) {

        }
    }, [])
    useEffect(() => {
        getCountry()
    }, [getCountry])

    const toastMessage = (message: string, type: string) => {
        props.toastManager.add(message, {
            appearance: type,
        });
    };

    const editVendorDetails = async (e: any) => {
        e.preventDefault();

        try {

            const res = await PUT(`vendor/${vendor?.id}`, data);
            if (res) {
                toastMessage('Succesfully updated vendor profile', 'success');
                const user = JSON.parse(localStorage?.getItem('koboCareVendor_user') ?? '{}');
                const updatedUser = {...user, vendor: {...user.vendor, ...res.data.data.vendor} };
                localStorage.setItem("koboCareVendor_user", JSON.stringify(updatedUser));
                store.dispatch(setCurrentUser(updatedUser));

            }

            setTimeout(() => {
                window.location.reload();
            }, 2000)
        } catch (error:any) {
            toastMessage(error.response.data.message, 'error');
        }
    }

    const onChange = (e: { target: HTMLInputElement }) => {
        const { name, value } = e.target;
        setData(prev => ({ ...prev, ...{ [name]: value } }))

    }

    const handleUploadImage = (e: React.ChangeEvent<any>, type: string) => {
        let reader: objInterface = new FileReader();
        let file: FileList = e.target.files[0];
        reader.onloadend = () => {
            if(type === 'profile') {
                setPreview(reader.result);
            }
            setCacPrev(reader.result);
            postImage(file, type);
        };

        return reader.readAsDataURL(file);
    }

    async function postImage(file: any, type: string) {
        const token = localStorage.getItem('koboCareVendor_tk');

        try {
          const config = {
            headers: {
              'Content-Type': file.type,
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent: objInterface) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
              },
          };
          const data = new FormData();
          data.append('media', file);
    
          const res = await axios.post(
            `${process.env.REACT_APP_MEDIA}?purpose=kobocare&tag=${props.user.vendor.id}`,
            data,
            config,
          );
          if (res && res.data.data && res.data.data.media) {
            uploadLogo(res.data.data.media.thumb, type);
          }
        } catch (error) {
          console.log(error);
        }
    }

    async function uploadLogo(image: string, type: string) {
        try {
          let data;
          if(type === 'profile') {
            data = { image };
          } else {
              data = { cac_image: image}
          }
          const res = await PUT(`vendor/${vendor?.id}`, data);
          
          if (res) {
              toastMessage('Succesfully updated vendor profile image', 'success');
              const user = JSON.parse(localStorage?.getItem('koboCareVendor_user') ?? '{}');
              const updatedUser = {...user, vendor: {...user.vendor, ...res.data.data.vendor} };
              localStorage.setItem("koboCareVendor_user", JSON.stringify(updatedUser));
              store.dispatch(setCurrentUser(updatedUser));

              if(type === 'cac') {
                  window.location.reload();
              }

          }
        } catch (error:any) {
          toastMessage(error.response.data.message, 'error');
          setCacPrev('');
          setPreview('');
          setProgress(0);



        }
      }

      const viewImage = (img: string) => {
          setShowImage(true);
          setImage(img);
      }

      const changeDoc = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowImage(false);
        setImage('');
        setUpload(true);
      }
    return (
        <MainLayout>
            <ContentLayout
                title='Profile'>

                <div className="panel">
                    <div className="left mg-03">
                        <div className="mb-2 blue pointer" onClick={goBack}>
                            <i className="fas fa-angle-left" /> <span>Back</span>
                        </div>
                        <ImageUploader progress={progress}  profile vendorImage={vendor.image} onChange={(e: React.ChangeEvent<HTMLInputElement> )=> handleUploadImage(e, 'profile')} preview={preview} />
                        <AsideMenu
                            className="mt-0"
                            title={"Vendor ID"}
                            subTitle={vendor?.code}
                        >
                            <AsideMenu.MenuItem
                                to={`/stations`}
                                icon={require("../../assets/images/gas-load.svg")}
                                title="Manage Station"
                            />
                            <AsideMenu.MenuItem
                                to={`/products`}
                                icon={require("../../assets/images/Box.svg")}
                                title="Manage Products"
                            />
                            <AsideMenu.MenuItem
                                to={`/user-management`}
                                icon={require("../../assets/images/multiple-users-1.svg")}
                                title="Manage Users"
                            />
                        </AsideMenu>
                    </div>
                    <div className="center">

                        <InfoBox
                            title={"General Information"}
                            vendor={vendor?.name}
                            location={''}
                            country={vendor?.country}
                            editText={"Edit Vendor"}
                            onEdit={vendor.admin.role.toLowerCase() !== 'attendant' ? () => setEdit(true) : () => alert('You have no access to do this')}
                        >
                            <div>
                                <span>Contact Person</span>
                                <span>{vendor?.admin?.first_name} {vendor?.admin?.last_name}</span>
                            </div>
                            <div>
                                <span>Phone Number</span>
                                <span>{vendor?.admin?.mobile}</span>
                            </div>
                            <div>
                                <span>Email</span>
                                <span>{vendor?.admin?.email}</span>
                            </div>
                            <div>
                                <span>Address</span>
                                <span>{vendor.location}</span>
                            </div>

                            <div>
                                <span>Registration Number</span>
                                <span>{vendor.reg_number}</span>
                            </div>
                            <div>
                                <span>Registration Document</span>
                                {vendor.cac_image ? <img src={vendor.cac_image} alt={vendor.name} onClick={() => viewImage(vendor.cac_image)} /> : <button onClick={() => setUpload(true)}>Upload Document</button>}
                            </div>
                        </InfoBox>



                    </div>
                    <div className="right">
                        <div className={"flex-column"}>
                            <Card
                                className={"green-card mb-1"}
                                title={vendor?.number_of_stations}
                                description={"Stations"}
                                icon={require("../../assets/images/gas-load-white.svg")}
                            />
                            <Card
                                className={"light-blue-card mb-1"}
                                title={vendor?.number_of_products}
                                description={"Products"}
                                icon={require("../../assets/images/Box-white.svg")}

                            />
                            <Card
                                className={"yellow-card"}
                                title={vendor?.number_of_users}
                                description={"Users"}
                                icon={require("../../assets/images/user.svg")}

                            />
                        </div>
                    </div>
                </div>

                {edit &&
                    <Modal
                        onNullClick={(e) => e.stopPropagation()}
                        onClick={() => setEdit(false)}
                    >
                        <Form
                            title={"Edit Vendor"}
                            footer={
                                <Form.Footer className={"justify-center align-center"}>
                                    <Form.Save
                                        title={"Update"}
                                        onSave={(e) => editVendorDetails(e)}
                                    />
                                </Form.Footer>
                            }
                        >
                            <Form.Input
                                type={"text"}
                                label={"Business Name"}
                                onChange={onChange}
                                name={"name"}
                                defaultValue={vendor.name}
                            />
                           
                            <Form.Select
                                name="country"
                                value={data.country.toLowerCase() }
                                label={"Country"}
                                onChange={onChange}

                            >
                                <option></option>

                                {countries
                                    ? sortArray(countries, 'country').map((country: any) => (
                                        <option
                                            key={country.countryCode}
                                            value={country.country}
                                        >
                                            {formatWord(country.country)}
                                        </option>
                                    ))
                                    : ""}
                            </Form.Select>
                            <Form.Input
                                type={"text"}
                                label={"location"}
                                name={"location"}
                                onChange={onChange}
                                defaultValue={vendor.location}
                            />

                            <Form.Input
                                type={"text"}
                                label={"Reg Number"}
                                name={"reg_number"}
                                onChange={onChange}
                                defaultValue={vendor.reg_number}
                            />

                        </Form>
                    </Modal>
                }

                {
                    upload &&
                    <Modal
                        onNullClick={(e) => e.stopPropagation()}
                        onClick={() => setUpload(false)}
                    >
                         <Form
                            title={"Upload Registration Document"}
                            
                        >
                           <ImageUploader progress={progress} title="Click to Upload Registration Document" onChange={(e: React.ChangeEvent<HTMLInputElement> )=> handleUploadImage(e, 'cac')} preview={cacPrev} />

                        </Form>

                    </Modal>

                }

                {
                    showImage &&
                    <Modal
                        onNullClick={(e) => e.stopPropagation()}
                        onClick={() => setShowImage(false)}
                    >
                         <Form
                            title={"Registration Document"}
                            footer={
                                <Form.Footer className={"justify-center align-center"}>
                                    <Form.Save
                                        title={"Change Document"}
                                        onSave={(e) => changeDoc(e)}
                                    />
                                </Form.Footer>
                            }
                            
                        >
                           <img src={image} alt="registration Document" />

                        </Form>

                    </Modal>

                }
            </ContentLayout>

        </MainLayout>
    );
}


const mapStateToProps = (state: any) => {
    return {

        user: state.auth.user
    };
};

export default connect(mapStateToProps, {})(AuthProvider(withToastManager(Profile)));