import * as React from "react";
import { ReactNode } from "react";
import "./Form.scss";
import Spinner from "../Spinner/Spinner";

export interface IProps {
  className?: string;
  title?: string;
  cancelText?: string;
  onCancel?: () => void;
  onSubmit?: (e: any) => void;
  children?: ReactNode;
  footer?: ReactNode;
}

function Form({
  title,
  className,
  footer,
  onCancel,
  onSubmit,
  children,
  cancelText,
}: IProps) {
  return (
    <div className={`form ${className ? className : ""}`}>
      <div className="form-container">
        <div className="form-container__heading">
          <p>{title}</p>
          {onCancel && (
            <p className="red cancel" onClick={() => onCancel()}>
              {cancelText ? cancelText : "CANCEL"}
            </p>
          )}
        </div>
        <form
          className="form-container__content"
          onSubmit={(e) => onSubmit && onSubmit(e)}
        >
          {children}
        </form>
      </div>
      {footer}
    </div>
  );
}

interface FormFooterNavProps {
  children: ReactNode;
  className?: string;
};

function Footer({ children, className }: FormFooterNavProps) {
  return (
    <div
      className={`form-container__footer d-flex ${className ? className : ""}`}
    >
      {children}
    </div>
  );
}

interface PrevProps  {
  onPrev: () => void;
  className?: string;
  title?: string;
  disabled?: boolean;

};

const Prev = ({ className, onPrev, title, disabled }: PrevProps) => {
  
  return (
     <div
      className={`d-flex align-center green pointer ${
        disabled ? "button__disabled" : ""
      } ${className}`}
      onClick={disabled ? (e: any) => e.preventDefault() : onPrev}
    >
      <img
        src={require("../../../assets/images/arrow-button-circle-right-1.svg")}
        alt="arrow-button"
      />
      {title}
    </div>
  );
};

interface NextProps {
  onNext: (e?: any) => void;
  className?: string;
  loading?: boolean;
  title: string;
};

const Next = ({ className, onNext, loading, title }: NextProps) => {
  return (
    <div
      className={`d-flex align-center green pointer ${className}`}
      onClick={onNext}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {title}
          <img
            src={require("../../../assets/images/arrow-button-circle-right.svg")}
            alt="arrow-button"
          />
        </>
      )}
    </div>
  );
};

interface SaveProps {
  onSave: (e: any) => void;
  className?: string;
  title: string;
  loading?: string;
};

const Save = ({ className, onSave, title, loading }: SaveProps) => {
  return (
    <button
      type={"submit"}
      className={`save d-flex align-center justify-center green pointer ${className}`}
      onClick={(e: any) => onSave && onSave(e)}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <span>{title}</span>
          <img
            src={require("../../../assets/images/check-circle-1.svg")}
            alt="arrow-button"
          />
        </>
      )}
    </button>
  );
};

interface InputProps {
  name?: string;
  value?: string;
  className?: string;
  label?: string;
  dropdown?: true;
  inputClassName?: string;
  defaultValue?: string;
  children?: ReactNode;
  aside?: ReactNode;
  onChange?: (e: any) => void;
  placeHolder?: string;
  error?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
};

function Input({
  placeHolder,
  onChange,
  error,
  className,
  defaultValue,
  inputClassName,
  dropdown,
  disabled,
  children,
  label,
  type,
  required,
  aside,
  value,
  name,
}: InputProps) {
  return (
    <div
      className={`form-container__contentInput ${className ? className : ""}`}
    >
      <small>{label}</small>
      {children ? (
        children
      ) : (
        <>
          <input
            name={name}
            disabled={disabled}
            defaultValue={defaultValue}
            type={type}
            value={value}
            required={required}
            className={`input ${inputClassName ? inputClassName : ""}`}
            onChange={(e) => onChange && onChange(e)}
            placeholder={placeHolder}
            autoComplete="off"
          />
        </>
      )}

      {error ? <p>{error}</p> : ""}
    </div>
  );
}

interface SelectProps {
  value?: string;
  className?: string;
  name?: string;
  inputClassName?: string;
  onChange?: (e: any) => void;
  placeHolder?: string;
  children: ReactNode;
  required?: boolean;
  error?: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
};

function Select({
  placeHolder,
  children,
  onChange,
  name,
  error,
  className,
  required,
  inputClassName,
  value,
  defaultValue,
  label,
  disabled
}: SelectProps) {
  return (
    <div
      className={`form-container__contentInput ${className ? className : ""}`}
    >
      <small>{label}</small>
      <select
        name={name}
        required={required}
        value={`${value ? value : ""}`}
        defaultValue={defaultValue}
        className={`${inputClassName ? inputClassName : ""}`}
        placeholder={placeHolder}
        onChange={(e) => onChange && onChange(e)}
        disabled={disabled}
      >
        {children}
      </select>
      {error ? <p>{error}</p> : ""}
    </div>
  );
}

interface CheckBoxProps {
  checked?: boolean;
  className?: string;
  inputClassName?: string;
  name?: string;
  required?: boolean;
  value?: string;
  onChange?: (e: any) => void;
  placeHolder?: string;
  error?: string;
  label: string;
};

function CheckBox({
  placeHolder,
  onChange,
  error,
  className,
  inputClassName,
  checked,
  required,
  name,
  value,
  label,
}: CheckBoxProps) {
  return (
    <div className={` ${className ? className : ""}`}>
      <span
        className={`checkBoxContainer ${inputClassName ? inputClassName : ""}`}
      >
        <input
          type="checkbox"
          placeholder={placeHolder}
          name={name}
          value={value}
          required={required}
          onChange={(e) => onChange && onChange(e)}
          checked={checked}
          className={`${inputClassName ? inputClassName : ""}`}
        />
        <span className="checkmark" />
        <small>{label}</small>
      </span>
      {error ? <p>{error}</p> : ""}
    </div>
  );
}

function Radio({
  placeHolder,
  onChange,
  error,
  className,
  inputClassName,
  checked,
  required,
  name,
  value,
  label,
}: CheckBoxProps) {
  return (
    <div className={`radio-button ${className ? className : ""}`}>
      <span
        className={`radio-button_item ${inputClassName ? inputClassName : ""}`}
      >
        <label>
          <input
            type="radio"
            placeholder={placeHolder}
            name={name}
            value={value}
            required={required}
            onChange={(e) => onChange && onChange(e)}
            checked={checked}
            className={`${inputClassName ? inputClassName : ""}`}
          />
          <span>{label}</span>
          <div className="check"></div>
        </label>
      </span>
      {error ? <p>{error}</p> : ""}
    </div>

    // <div className={`radio d-flex align-center ${className ? className : ""}`}>

    //   <span className={` ${inputClassName ? inputClassName : ""}`}>
    //     <input
    //       type="radio"
    //       placeholder={placeHolder}
    //       name={name}
    //       value={value}
    //       required={required}
    //       onChange={(e) => onChange && onChange(e)}
    //       checked={checked}
    //       className={`${inputClassName ? inputClassName : ""}`}
    //     />
    //     <small>{label}</small>
    //   </span>
    //   {error ? <p>{error}</p> : ""}
    // </div>
  );
}

Form.Footer = Footer;
Form.Prev = Prev;
Form.Next = Next;
Form.Save = Save;
Form.Input = Input;
Form.Select = Select;
Form.CheckBox = CheckBox;
Form.Radio = Radio;

export default Form;
