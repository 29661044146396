import * as React from "react";
import "./Message.scss";

type MessageProps = {
  error?: boolean;
  errMsg?: string;
  className?: string;
};

function Message({ errMsg, error, className }: MessageProps) {
  return (
    <>
      {error ? (
        <span
          className={`error padding-bottom-10 ${className ? className : ""}`}
        >
          <small>{errMsg}</small>
        </span>
      ) : (
        ""
      )}
    </>
  );
}

export default Message;
