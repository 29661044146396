import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addStation,
  fetchStations,
} from "../../../store/actions/station/station";
import { fetchUsers } from "../../../store/actions/user/user";
import { withToastManager } from "react-toast-notifications";
import { GET, POST, PUT } from "../../../core/config/Api";
// import Button from "../../../shared/components/Button/Button";

export interface VendorStationProviderProps {
  currentPage: number;
  limit?: string | number;
  fetchUsers?: any;
  fetchStations: (url: string) => Promise<any>;
  addStation?: any;
  render: any;
  toastManager?: any;
  match?: any;
  tableHeadings?: any[];
  items?: {
    [key: string]: any
  };
  state: any;
  total?: number;
  totalPages?: number;
  next: () => void;
  prev: () => void;
  history: {
    push: (param: string) => void;
    goBack: () => void;
  };
  vendor: {[key: string]: any};
  onSearch: any;
};

class VendorStationProvider extends Component<VendorStationProviderProps> {
  state = {
    display: "none",
    manage: false,
    location: "",
    lat: '',
    lng: '',
    loading_: false,
    fetching: false,
    loading: false,
    name: "",
    station: {},
    state: "",
    code: false,
    manager: "",
    country: "",
    manager_id: "",
    rate: "",
    searchTerm: '',
    status: null,
    stationProd: [],
    users: [],
    field: "",
    product: {
      _id: "",
    },
    land_mark: '',
    show: false,
    error: false,
    currentPage: this.props.currentPage,
    limit: this.props.limit,
    total: this.props.total,
  };  

  componentDidMount() {
    document.title = "Kobo Care | Stations";
    const { fetchStations } = this.props;
    this.setState({ loading: true}, () => {
      setTimeout(() => {
        fetchStations(
          `vendor/${this.props?.vendor?.id}/stations?limit=${this.props.limit}&page=${this.props.currentPage}`
        )
        .then(() => {
          this.setState({ loading: false, fetching: true });
  
          GET(`vendor/${this.props?.vendor?.id}/users?limit=1000`).then((res) => {
            this.setState({ users: res.data.data.users, fetching: false });
          });
  
        })
        .catch((err: any) => {
          this.setState({ loading: false, fetching: false });
        });
      }, 2000)
    });
      
      
  }
  
  addStation = (e: any) => {
    e.preventDefault();
    this.setState({ loading_: true });
    const { name, location, manager_id, state, country, lat, lng, land_mark } = this.state;
    const data = {
      name,
      location,
      manager_id: parseInt(manager_id, 10),
      state,
      country,
      lat,
      lng,
      land_mark
    };
    this.props
      .addStation(`vendor/${this.props.vendor.id}/station`, data)
      .then((res: any) => {
        this.toastMessage("Station successfully added", "success");
        this.setState({ loading_: false, name: "", location: "", state: "" });
        this.props.history.push('/stations');
      })
      .catch((err: any) => {
        this.toastMessage(err.response.data.message, "error");
        this.setState({ loading_: false });
      });
  };
  onSearch = (e: any) => {
    return;
  }
  setDisplay = () => {
    this.setState({ display: "panel" });
  };

  // setManage = (e: any, station: any) => {
  //   this.setState({ loading_: true });
  //   GET(`product/${this.props.vendor.id}/station/${station.id}`)
  //     .then((res) => {
  //       this.setState({
  //         manage: true,
  //         loading_: false,
  //         error: false,
  //         station,
  //         stationProd: res.data.data.products,
  //       });
  //     })
  //     .catch((err) => {
  //       this.setState({ manage: true, error: true, loading_: false, station });
  //     });
  // };

  onChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  closePanel = () => {
    this.setState({ display: "panel-out", manage: false });
  };

  showManage = () => {
    this.setState({ manage: true, display: "panel" });
  };

  closeManage = () => {
    this.setState({ manage: false, display: "panel-out" });
  };

  showCode = () => {
    if (this.state.code) {
      this.setState({ code: !this.state.code });
    } else {
      this.setState({ code: !this.state.code });
    }
  };

  statusChange = (e: any, prod: any, station: any) => {
    this.setState({ loading_: true });
    if (this.state.status) {
      this.setState({ status: false }, () => {
        PUT(`product/${prod._id}/stations/${station.id}/activate`, {
          status: this.state.status,
        })
          .then((res) => {
            this.toastMessage(
              "Product successfully unapproved to a station",
              "success"
            );
            this.setState({ loading_: false, show: false });
          })
          .catch((err) => {});
        this.setState({ loading_: false, show: false });
      });
    } else {
      this.setState({ status: true, loading_: true }, () => {
        PUT(`product/${prod._id}/stations/${station.id}/activate`, {
          status: this.state.status,
        })
          .then((res) => {
            this.toastMessage(
              "Product successfully approved to a station",
              "success"
            );
            this.setState({ loading_: false, show: false });
          })
          .catch((err) => {
            this.setState({ loading_: false, show: false });
          });
      });
    }
  };

  updateRate = (e: any, station: any) => {
    e.preventDefault();
    this.setState({ loading_: true });

    const { rate, product } = this.state;
    if (rate.length < 1) {
      return null;
    }
    const data = {
      stationId: station.id,
      rate: parseInt(rate, 10),
      vendorId: parseInt(this.props.vendor.id, 10),
    };
    POST(`product/${product._id}/setStationRate`, data)
      .then((res: any) => {
        this.toastMessage("Product rate successfully updated", "success");
        this.setState({ loading_: false, field: "" });
      })
      .catch((err: any) => {
        this.setState({ loading_: false });
      });
  };

  onEdit = (e: any, prod: any) => {
    if (this.state.field.length < 1) {
      this.setState({ field: prod.name, product: prod });
    } else {
      this.setState({ field: "", product: prod });
    }
  };

  showModal = (e: any, product: any, station: any) => {
    if (!this.state.show) {
      this.setState({
        show: !this.state.show,
        station,
        product,
        status: !e.target.checked,
      });
    } else {
      this.setState({
        show: !this.state.show,
        station,
        product,
        status: !e.target.checked,
      });
    }
  };

  closeModal = (e: any) => {
    if (!e.target.checked) {
      this.setState({ show: !this.state.show, status: false });
    } else {
      this.setState({ show: !this.state.show, status: true });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  next = (e: any) => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1, loading: true }, () => {
      this.props
        .fetchStations(
          `vendor/${this.props.vendor.id}/stations?limit=${this.state.limit}&page=${this.state.currentPage}`
        )
        .then((res: any) => {
          this.setState({ loading: false });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
        });
    });
  };

  previous = (e: any) => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1, loading: true }, () => {
      this.props
        .fetchStations(
          `vendor/${this.props.vendor.id}/stations?limit=${this.state.limit}&page=${this.state.currentPage}`
        )
        .then((res: any) => {
          this.setState({ loading: false });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
        });
    });
  };

  toastMessage = (message: string, type: string) => {
    this.props.toastManager.add(message, {
      appearance: type,
    });
  };

  viewStation = (vendor: {[key: string]: any}) => {
    this.props.history.push(`/stations/${vendor.id}`,);
  }

  
  pageTitle = () => {
    switch (this.props.match.path) {
      case "/stations":
        return "Stations";
      case "/stations/:id/add-station":
        return "Add Station";
      case "/stations/:id":
        const station = this.props.items?.find((item: {[key: string]: any}) => item.id === +this.props.match.params.id);
       return `${station?.name ?? ''}`;
      default:
        return "";
    }
  };
  render() {

    const tableHeadings: any[] = [
      {
        key: "name",
        title: "Station Name",
        onClick: (data: any) => {
          this.viewStation(data);

        },
      },
      {
        key: "code",
        title: "ID",
        onClick: (data: any) => {
          this.viewStation(data);

        },
      },
      {
        key: "location",
        title: "Location",
        onClick: (data: any) => {
          this.viewStation(data);
        },
      },
      
      {
        key: "station",
        title: "Manager",
        onClick: (data: any) => {
          this.viewStation(data);

        },
        formatter: (data: any) => {
          return (
            <p className="tableText">
              {data.manager !== null ? data.manager.first_name : "not"}{" "}
              {data.manager !== null ? data.manager.last_name : "available"}
            </p>
          );
        },
      },
      // {
      //   key: " ",
      //   title: "Manage",
      //   formatter: (data: any) => {
      //     return (
      //       <Button
      //         type={"button"}
      //         title={"Manage"}
      //         className={"button--sm button--sec-blue"}
      //         onClick={(e: any) => this.setManage(e, data)}
      //       />
      //     );
      //   },
      // },
      {
        key: "options",
        title: "Options",
        onClick: (data: any) => {
          this.viewStation(data);
        },
        formatter: (data: any) => {
          return (
            <>
              <div className="tableText text-left">
                <div className="position-relative boxContainer">
                  <img
                    // onClick={() => handleOptions(data)}
                    src={require("../../../assets/images/option.svg")}
                    alt=""
                  />
                  {/* <div className="action-box">
                    <div
                      className="action-item"
                      onClick={() => {
                        setShow(true);
                        setUser(data);
                        //   editUser(data);
                      }}
                    >
                      Edit User
                    </div>
                    <div
                      className="action-item"
                      onClick={() => {
                        _setShow(true);
                        setUser(user);
                      }}
                    >
                      Edit Password
                    </div>
                  </div> */}
                  {/* )} */}
                </div>
              </div>
            </>
          );
          }
      }
    ];
    return this.props.render({
      tableHeadings,
      state: this.state,
      next: this.next,
      prev: this.previous,
      totalPages: this.props.totalPages,
      items: this.props.items,
      total: this.props.total,
      setDisplay: this.setDisplay,
      onEdit: this.onEdit,
      onChange: this.onChange,
      addStation: this.addStation,
      closePanel: this.closePanel,
      closeManage: this.closeManage,
      showModal: this.showModal,
      showCode: this.showCode,
      updateRate: this.updateRate,
      goBack: this.goBack,
      statusChange: this.statusChange,
      closeModal: this.closeModal,
      currentPage: this.props.currentPage,
      limit: this.props.limit,
      vendor: this.props.vendor,
      pageTitle: this.pageTitle,
      onSearch: this.onSearch
    });
  }
}

const mapStateToProps = (state: any) => {
  return {
    items: state?.station?.stations?.stations,
    currentPage: state.station.stations.currentPage,
    limit: state.station.stations.limit,
    total: state.station.stations.total,
    totalPages: state.station.stations.totalPages,
    vendor: state.auth?.user?.vendor

  };
};

export default connect<{}, {}, any>(mapStateToProps, {
  addStation,
  fetchStations,
  fetchUsers,
})(withToastManager(VendorStationProvider));
