import { ADD_USER, SET_USERS } from "../../types/types";
import { POST, GET } from "../../../core/config/Api";
import { SET_CURRENT_USER } from "../../types/types";

export const setCurrentUser = (user: any) => {
  return {
    type: SET_CURRENT_USER,
    user,
  };
};
export const setUser = (users: any) => {
  return {
    type: SET_USERS,
    users,
  };
};

export const saveUser = (user: any) => {
  return {
    type: ADD_USER,
    user,
  };
};
export const addUser = (endpoint: string, data: any) => (dispatch: any) => {
  return POST(endpoint, data).then((res: any) => {
    dispatch(saveUser(res.data.data));
  });
};

export const fetchUsers = (endpoint: string) => (dispatch: any) => {
  return GET(endpoint).then((res: any) => {
    dispatch(setUser(res.data.data));
  });
};
