import * as React from "react";
import "./ResetPassword.scss";
import FormContainer from "../../shared/components/FormContainer/FormContainer";
import Form from "../../shared/components/Form/Form";
import Button from "../../shared/components/Button/Button";
import {Route} from "react-router";

type PageProps = {
    history: {
        push: (param: string) => void;
    };
};

function ResetPassword(props: PageProps) {
    return (
        <div>
            <FormContainer>
                <Route path={"/forgot-password"} exact render={ResetEmailForm}/>
                <Route path={"/reset-password"} exact render={ResetPasswordForm}/>
            </FormContainer>
        </div>
    );
}

const ResetEmailForm = (props: PageProps) => {
    return (
        <Form
            className={"form__login"}
            onSubmit={() => console.log("form submitted")}
            title={"Sign in"}
        >
            <Form.Input type={"email"} label={"Email"}/>
            <Button
                type={"button"}
                onClick={() => props.history.push("/reset-password")}
                className={"button--green"}
                title={"Sign In"}
            />
        </Form>
    );
};

const ResetPasswordForm = (props: PageProps) => {
    return (
        <Form
            className={"form__login"}
            onSubmit={() => console.log("form submitted")}
            title={"Sign in"}
        >
            <Form.Input type={"password"} label={"Password"}/>
            <Form.Input type={"password"} label={"Confirm Password"}/>
            <Button
                type={"button"}
                onClick={() => props.history.push("/")}
                className={"button--green"}
                title={"Sign In"}
            />
        </Form>
    );
};

export default ResetPassword;
