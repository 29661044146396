import {POST, GET} from "../../../core/config/Api";
import {SET_CURRENT_USER} from "../../types/types";

export const setCurrentUser = (user: any) => {
    return {
        type: SET_CURRENT_USER,
        user,
    };
};

export const auth = (endpoint: string, data: {[key: string]: any}) => (dispatch: any) => {
    return POST(endpoint, data)
    .then((res: {[key: string]: any}) => {
        const token = res.data.data.token;
        localStorage.setItem("koboCareVendor_tk", token);
        return res.data.data.user; 
    }).then(user => {
        GET(`vendor/userVendor/${user.id}`)
        .then(resp => {
            localStorage.setItem("koboCareVendor_user", JSON.stringify({...user, ...resp.data.data}));
            dispatch(setCurrentUser({...user, ...resp.data.data}));
            return resp.data.data
        })
        
    });
};

export const logout = () => (dispatch: any) => {
    localStorage.removeItem("koboCareVendor_tk");
    dispatch(setCurrentUser({isAuthenticated: false}));
};
