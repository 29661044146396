import * as React from "react";
import { ReactNode } from "react";
import "./Modal.scss";

export interface Props {
  children?: ReactNode;
  className?: string;
  onNullClick: (e: any) => void;
  onClick?: (e: any) => void;
}

function Modal({ children, onClick, onNullClick, className }: Props) {
  return (
    <div
      className={`modal ${className ? className : ""}`}
      onClick={(e) => onClick && onClick(e)}
    >
      <div className="modalBlock">
        <ModelContentBlock
          onClick={(e) => onClick && onClick(e)}
          onNullClick={onNullClick}
        >
          {children}
        </ModelContentBlock>
      </div>
    </div>
  );
}

type ContentBlockProps = {
  children: ReactNode;
  onNullClick: (e: any) => void;
  onClick: (e: any) => void;
};

const ModelContentBlock = ({
  children,
  onNullClick,
  onClick,
}: ContentBlockProps) => {
  return (
    <div className="modalWrapper position-relative">
      <div className="closeModal position-absolute">
        <img
          alt="close modal"
          src={require("../../../assets/images/close.svg")}
          onClick={onClick}
        />
      </div>
      <div
        className="modalBlock-content"
        onClick={(e) => onNullClick && onNullClick(e)}
      >
        {children}
      </div>
    </div>
  );
};

Modal.ContentBlock = ModelContentBlock;

export default Modal;
