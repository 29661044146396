import {ADD_TRUCK, SET_TRUCKS} from "../../types/types";

type Action = {
    type: "SET_TRUCKS" | "ADD_TRUCK";
    fleets: any[];
    fleet: any;
};

const initialState: InitialState = {
    fleets: {
        fleets: [],
    },
};


type InitialState = {
    fleets: {
        fleets: any
    };
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_TRUCKS:
            return {
                ...state,
                fleets: action.fleets,
            };
        case ADD_TRUCK:
            return {
                ...state,
                fleets: {
                    ...state.fleets,
                    fleets: [{...action.fleet}, ...state.fleets.fleets],
                },
            };
        default:
            return state;
    }
};
