import {
  ADD_PRODUCT,
  SET_PRODUCTS,
  UPDATE_PRODUCT,
  EXTEND_CONTRACT,
} from "../../types/types";
import { POST, GET, PUT } from "../../../core/config/Api";
import { SET_CURRENT_USER } from "../../types/types";

export const setCurrentUser = (user: any) => {
  return {
    type: SET_CURRENT_USER,
    user,
  };
};
export const setProduct = (products: any) => {
  return {
    type: SET_PRODUCTS,
    products,
  };
};

export const saveProduct = (product: any) => {
  return {
    type: ADD_PRODUCT,
    product,
  };
};

export const updateProduct = (product: any) => {
  return {
    type: UPDATE_PRODUCT,
    product,
  };
};

export const extend = (contract: any) => {
  return {
    type: EXTEND_CONTRACT,
    contract,
  };
};

export const addProduct = (endpoint: string, data: any) => (dispatch: any) => {
  return POST(endpoint, data).then((res: any) => {
    dispatch(saveProduct(res.data.data.product));
  });
};

export const addVendorProduct = (endpoint: string, data: any) => (
  dispatch: any
) => {
  return POST(endpoint, data).then((res: any) => {
    dispatch(saveProduct(res.data.data.vendorProduct));
  });
};

export const fetchProducts = (endpoint: string) => (dispatch: any) => {
  return GET(endpoint).then((res: any) => {
    dispatch(setProduct(res.data.data));
  });
};

export const editProduct = (endpoint: string, data: any) => (dispatch: any) => {
  return PUT(endpoint, data).then((res: any) => {
    dispatch(updateProduct(res.data.data.product));
  });
};

export const extendContract = (endpoint: string, data: any) => (
  dispatch: any
) => {
  return POST(endpoint, data).then((res: any) => {
    dispatch(extend(res.data.data));
  });
};
