import {ADD_PRODUCT_RECONCILIATION, SET_PRODUCT_RECONCILIATIONS,} from "../../types/types";


type InitialState = {
    productReconciliations: {
        productReconciliations: any[];
    };
};

const initialState: InitialState = {
    productReconciliations: {
        productReconciliations: [],
    },
};

type Action = {
    type: "SET_PRODUCT_RECONCILIATIONS" | "ADD_PRODUCT_RECONCILIATION";
    products?: any[];
    productReconciliation?: any;
    stations?: any[];
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_PRODUCT_RECONCILIATIONS:
            return {
                ...state,
                productReconciliations: action.products,
            };

        case ADD_PRODUCT_RECONCILIATION:
            return {
                ...state,
                productReconciliations: {
                    ...state.productReconciliations,
                    productReconciliations: {
                        ...state.productReconciliations.productReconciliations,
                        ...action.productReconciliation,
                    },
                },
            };
        default:
            return state;
    }
};
