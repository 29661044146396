import React from "react";
// import VendorProvider from "./VendorProvider";
import ContentLayout from "../../shared/components/ContentLayout/ContentLayout";
import { Route } from "react-router";
// import AddProduct from "./AddProduct/AddProduct";
// import AddVendor from "./AddVendor/AddVendor";
import VendorStation from "./VendorStation/VendorStation";
// import VendorUser from "./VendorUser/VendorUser";
// import VendorProduct from "./VendorProduct/VendorProduct";
import MainLayout from "../../shared/components/MainLayout/MainLayout";
import  { VendorListProps } from "./VendorList/VendorList";
// import VendorUserProvider, {
//   VendorUserProviderProps,
// } from "./VendorUser/VendorUserProvider";
import VendorStationProvider, {
  VendorStationProviderProps,
} from "./VendorStation/VendorStationProvider";
// import AddUser from "./AddUser/AddUser";
import AddStation from "./AddStation/AddStation";
// import VendorProductProvider, {
//   VendorProductProviderProps,
// } from "./VendorProduct/VendorProductProvider";
// import VendorPanel from "./VendorPanel/VendorPanel";
import SingleStation from './SingleStation/SingleStation';

type PageProps = {
  history: {
    push: (param: string) => void;
  };
};

export interface ProviderProps extends VendorListProps {
  tableHeadings: any[];
  data: any[];
  items: {
    vendors: any[];
  };
  addVendor: () => void;
  closePanel: () => void;
  onChange: () => void;
  pageTitle: () => void;
  handleOnChange: any;
  vendor: {[key: string]: any};
  editVendor: (e: any, data: any) => void;
  editVendorDetails: (e: any, data: any) => void;
  closeEditModal: () => void;
  state: any;
  next: () => void;
  prev: () => void;
  totalPages: number;
  match: {
    params: {
      id: string;
    };
  };
  history: {
    push: (param: string) => void;
  };
}

function Vendor(props: PageProps) {
  return (
    <MainLayout>
      <VendorStationProvider
        {...props} // passes history props to vendor provider
        render={(providerProps: ProviderProps) => {
          return (
            <ContentLayout
              title={
                providerProps.pageTitle ? `${providerProps.pageTitle()}` : ""
              }
            >
              {/* <Route
                path="/vendors"
                exact
                render={(routeProps) => (
                  <VendorList {...routeProps} {...providerProps} />
                )} //passes provider props to vendor list
              /> */}

              {/* <Route
                path="/vendors/add-vendor"
                exact
                strict
                render={(routeProps) => (
                  <AddVendor {...routeProps} {...providerProps} />
                )}
              /> */}

              {/* <Route
                path="/vendor/:id"
                exact
                render={(routeProps: any) => (
                  <VendorStationProvider
                    {...routeProps}
                    render={(
                      vendorStationProps: VendorStationProviderProps
                    ) => {
                      return (
                        <VendorStation
                          {...vendorStationProps}
                          {...routeProps}
                        />
                      );
                    }}
                  />
                )}
              /> */}

              {/* <Route
                path="/vendor/:id/add-product"
                exact
                render={(routeProps) => (
                  <VendorProductProvider
                    {...routeProps}
                    render={(
                      vendorProductProps: VendorProductProviderProps
                    ) => {
                      return (
                        <AddProduct {...vendorProductProps} {...routeProps} />
                      );
                    }}
                  />
                )}
              /> */}

             

              <Route
                path="/stations"
                exact
                render={(routeProps: any) => (
                  <VendorStationProvider
                    {...routeProps}
                    render={(
                      vendorStationProps: VendorStationProviderProps
                    ) => {
                      return (
                        <VendorStation
                          {...vendorStationProps}
                          {...routeProps}
                        />
                      );
                    }}
                  />
                )}
              />

              <Route
                path="/stations/:id/add-station"
                exact
                render={(routeProps: any) => (
                  <VendorStationProvider
                    {...routeProps}
                    render={(
                      vendorStationProps: VendorStationProviderProps
                    ) => {
                      return (
                        <AddStation {...vendorStationProps} {...routeProps} />
                      );
                    }}
                  />
                )}
              />

            <Route
                path="/stations/:id"
                exact
                render={(routeProps) => (
                  <VendorStationProvider
                    {...routeProps}
                    render={(
                      vendorStationProps: VendorStationProviderProps
                    ) => {
                      return (
                        <SingleStation
                          {...vendorStationProps}
                          {...routeProps}
                        />
                      );
                    }}
                  />
                )}
              />

              {/* <Route
                path="/vendor/:id/users"
                exact
                render={(routeProps) => (
                  <VendorUserProvider
                    {...routeProps}
                    render={(vendorUserProps: VendorUserProviderProps) => {
                      return (
                        <VendorUser {...vendorUserProps} {...routeProps} />
                      );
                    }}
                  />
                )}
              /> */}

              {/* <Route
                path="/vendor/:id/add-user"
                exact
                render={(routeProps) => (
                  <VendorUserProvider
                    {...routeProps}
                    render={(vendorUserProps: VendorUserProviderProps) => {
                      return <AddUser {...vendorUserProps} {...routeProps} />;
                    }}
                  />
                )}
              /> */}
            </ContentLayout>
          );
        }}
      />
    </MainLayout>
  );
}

export default Vendor;
