import { objInterface } from "./interfaces";

// 1. Function to get UUID
const uuid = (seed = 100) =>
  Math.random()
    .toString(36)
    .substring(2, 2 + seed);

function getDash(count = 1) {
  return "—".repeat(count);
}

const naira = "₦";

const mobileNum = (mobile: string) => {
  const regex = /(\d)\s+(?=\d)/g;
  const subst = `$1`;

  // The substituted value will be contained in the result variable
  return mobile.replace(regex, subst);
};

function formatNumber(num: string | number) {
  if (num) return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return 0;
}

const capitalCaseWord = (word: string) => {
  return word.toUpperCase();
};

const formatWord = (word: string) => {
  if (word && word.length) return word[0].toUpperCase() + word.slice(1);
};

const handleSearch = function (searchTerm: any, items: any[]) {
  // Get the search terms from the input field
  if (!searchTerm) return [];
  // Tokenize the search terms and remove empty spaces
  const tokens = searchTerm
    .toLowerCase()
    .split(" ")
    .filter(function (token: any) {
      console.log("token", token);
      return token.trim().replace(/[^a-zA-Z ]/g, "") !== "";
    });

  if (tokens.length) {
    const searchTermRegex = new RegExp(tokens.join("|"), "gim");

    if (items && items.length) {
      return items.filter(function (item: any) {
        let itemString = "";
        for (let key in item) {
          console.log("item", key, item);
          if (
            item.hasOwnProperty(key) &&
            item[key] !== "" &&
            item[key] !== null
          ) {
            itemString += item[key].toString().toLowerCase().trim() + " ";
          }
        }
        return itemString.match(searchTermRegex);
      });
    }
  }
};

function buildQuery(data: objInterface) {
  // If the data is already a string, return it as-is
  if (typeof data === "string") return data;

  // Create a query array to hold the key/value pairs
  var query = [];

  // Loop through the data object
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        // Encode each key and value, concatenate them into a string, and push them to the array
        query.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        );
      }
    }
  }

  // Join each item in the array with a `&` and return the resulting string
  return query.join("&");
}

const downloadCard = (
  filename: string,
  userType: string,
  startDate: string,
  endDate: string
) => {
  const token = localStorage.getItem("koboCareVendor_tk");

  let data = buildQuery({
    userType,
    startDate,
    endDate,
  });

  //build the query params and add & to join them.
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  let configUrl = process.env.REACT_APP_URL;

  let url;
  if (startDate !== "" && endDate !== "") {
    url = `${configUrl}careWallet/download?${data}&limit=30000`;
  } else if (userType !== "") {
    url = `${configUrl}careWallet/download?${data}&limit=30000`;
  } else {
    url = `${configUrl}careWallet/download?`;
  }

  fetch(url, { headers, mode: "cors" })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
    })
    .catch((err) => {
      console.log(err, "err");
    });
};

export const downloadFile = (
  filename: any,
  path: string,
  userType?: any,
  startDate?: any,
  endDate?: any
) => {
  const token = localStorage.getItem("koboCareVendor_tk");

  // let data = buildQuery({
  //   userType,
  //   startDate,
  //   endDate,
  // });

  //build the query params and add & to join them.
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  let configUrl = process.env.REACT_APP_URL;

  let url = `${configUrl}${path}`;

  // if (startDate !== "" && endDate !== "") {
  //   url = `${configUrl}careWallet/download?${data}&limit=30000`;
  // } else if (userType !== "") {
  //   url = `${configUrl}careWallet/download?${data}&limit=30000`;
  // } else {
  //   url = `${configUrl}careWallet/download?`;
  // }

  fetch(url, { headers, mode: "cors" })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      if (link) {
        link.href = url;
        link.setAttribute("download", `${filename}.csv`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      }
    })
    .catch((err) => {
      console.log(err, "err");
    });
};

const sortArray = (arr: {[key: string]: any}, val: string) => {
  return arr.sort((a: any, b: any) => a[val] > b[val] ? 1 : -1)
}

export {
  getDash,
  uuid,
  formatNumber,
  formatWord,
  naira,
  downloadCard,
  capitalCaseWord,
  buildQuery,
  handleSearch,
  mobileNum,
  sortArray
};
