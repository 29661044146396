import * as React from "react";
import "./Card.scss";

export interface IProps {
  title?: string;
  description?: string;
  className?: string;
  icon?: string;
  onClick?: () => void;
}

function Card(props: IProps) {
  return (
    <div
      className={`card ${props.className || ""}`}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.icon ? <img src={props.icon} alt="" /> : ""}
      <div className="flex-column">
        <h4>{props.title}</h4>
        <h5>{props.description}</h5>
      </div>
    </div>
  );
}

export default Card;
