import React from "react";
import "./Empty.scss";
import Spinner from "../Spinner/Spinner";

type EmptyProps = {
  icon?: string;
  className?: string;
  loading?: boolean;
  description?: string;
};
function Empty(props: EmptyProps) {
  return (
    <div className={`empty ${props.className || ""}`}>
      {props.loading ? (
        <Spinner />
      ) : props.description ? (
        <>
          {props.icon ? <img src={props.icon} alt="empty icon" /> : ""}
          <p>{props.description}</p>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Empty;
