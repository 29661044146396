import {ADD_CARD, ADD_CARD_PRODUCT, FILTER_DATE, SET_CARDS, UPDATE_CARD,} from "../../types/types";

type InitialState = {
    cards: {
        limit: number,
        currentPage: number,
        wallets: any[];
    };
};

const initialState: InitialState = {
    cards: {
        limit: 30,
        currentPage: 1,
        wallets: [],
    },
};

type Action = {
    type:
        | "ADD_CARD"
        | "SET_CARDS"
        | "UPDATE_CARD"
        | "ADD_CARD_PRODUCT"
        | "FILTER_DATE";
    card?: any;
    reject?: any;
    cards?: any[];
};

export default (state = initialState, action: Action) => {
    switch (action.type) {
        case ADD_CARD:
            return {
                ...state,
                cards: {
                    ...state.cards,
                    cards: [
                        {
                            ...action.card.wallet,
                        },

                        ...state.cards.wallets,
                    ],
                },
            };

        case SET_CARDS:
            return {
                ...state,
                cards: action.cards,
            };

        case FILTER_DATE:
            return {
                ...state,
                cards: action.cards,
            };

        case UPDATE_CARD:
            return {
                ...state,
                cards: {
                    ...state.cards,
                    wallets: state.cards.wallets.map((card) => {
                        if (card._id === action.card.wallet._id) {
                            return action.card.wallet;
                        }
                        return card;
                    }),
                },
            };

        case ADD_CARD_PRODUCT:
            return {
                ...state,
                cards: {
                    ...state.cards,
                    wallets: state.cards.wallets.map((card) => {
                        if (card.cardNumber === action.card.wallet.cardNumber) {
                            return action.card.wallet;
                        }

                        return card;
                    }),
                },
            };

        default:
            return state;
    }
};
